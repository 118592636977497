<Side-test>
    <div class="side-menu-card" id="translation-tutorial-container">
        <img style="{applyFilters()}" width="25" src="{window.tamkin_src_base+'/images/close.svg'}"
            class="icon-close-side">
        <div id="translation-tutorial-subcontainer">


            <div each="{ trans_tut_card in props.message.translation_tutorial }" class="item-menu-translation">
                <div class="side-menu-content">
                    <h3>{ trans_tut_card.title }</h3>
                    <p>{ trans_tut_card.description }</p>
                </div>
            </div>
            <div class="btns-action">
                <button class="prevBtn"><i class="fa fa-arrow-left"></i>{ ("Prev") }</button>
                <button class="nextBtn">{ ("Next") }<i class="fa fa-arrow-right"></i></button>
                <button style="display: none;" class="doneBtn">{ ("Done") }<i class="fa fa-check"></i></button>
            </div>

        </div>



    </div>
    <div class="side-menu-card" id="tutorial-menu">
        <img style="{applyFilters()}" width="25" src="{window.tamkin_src_base+'/images/close.svg'}"
            class="icon-close-side">

        <h3>{ (props.message?.menu_tutorial_description) }</h3>

        <div class="btns-about-bottom info-config">
            <button class="btn" id="tutorial-info-btn">
                <img height="26" src={loadFile(props.message?.button_info_icon)}>{
                (props.message?.button_info_text) }
            </button>
            <button class="btn" id="tutorial-config-btn">
                <img style="{applyFilters()}" src={loadFile(props.message?.button_config_icon)}> {
                (props.message?.button_config_text) }
            </button>

        </div>



    </div>

    <div class="side-menu-card" id="tutorial-config">
        <img style="{applyFilters()}" width="25" src="{window.tamkin_src_base+'/images/close.svg'}"
            class="icon-close-side">
        <div each="{config_tut_card  in props.message.config_tutorial }" class="item-menu-config">

            <div class="side-menu-content">
                <h3>{ (config_tut_card?.title) }</h3>
                <p>{ (config_tut_card?.description) }</p>
            </div>
        </div>
        <div class="btns-action">
            <button class="prevBtn"><i class="fa fa-arrow-left"></i>{ ("Prev") }</button>
            <button class="nextBtn">{ ("Next") }<i class="fa fa-arrow-right"></i></button>
            <button style="display: none;" class="doneBtn">{ ("Done") }<i class="fa fa-check"></i></button>
        </div>



    </div>

    <div class="side-menu-card" id="tutorial-info">
        <img style="{applyFilters()}" width="25" src="{window.tamkin_src_base+'/images/close.svg'}"
            class="icon-close-side">
        <div each="{info_tut_card in props.message.info_tutorial }" class="item-menu-info">
            <div class="side-menu-content">
                <h3>{ (info_tut_card?.title) }</h3>
                <p>{ (info_tut_card?.description) }</p>
            </div>
        </div>

        <div class="btns-action">
            <button class="prevBtn"><i class="fa fa-arrow-left"></i>{ ("Prev") }</button>
            <button class="nextBtn">{ ("Next") }<i class="fa fa-arrow-right"></i></button>
            <button style="display: none;" class="doneBtn">{ ("Done") }<i class="fa fa-check"></i></button>
        </div>



    </div>


    <div class="side-menu-card" id="warning-menu">
        <img style="{applyFilters()}" width="25" src="{window.tamkin_src_base+'/images/close.svg'}"
            class="icon-close-side">
        <div class="side-menu-content">
            <h3 class="mb-3">{ (props.message?.warning_title) }</h3>
            <p>{ (props.message?.warning_description) }</p>
        </div>
        <div class="warning-actions">
            <div class="btns-action">
                <button class="text-main" id="yes">{ (props.message?.warning_inform_yes) }</button>
                <button id="no">{ (props.message?.warning_inform_no) }</button>
            </div>
        </div>
    </div>

    <div class="side-menu-card" id="asl-translate-menu">
        <img style="{applyFilters()}" width="25" src="{window.tamkin_src_base+'/images/close.svg'}"
            class="icon-close-side">
        <div class="side-menu-content">
            <h3 class="mb-3">{ (props.message?.asl_title) }</h3>
            <p>{ (props.message?.asl_description) }</p>
        </div>
        <div class="asl-actions">
            <div class="btns-action">
                <button class="text-main repeat"><i class="fa fa-repeat"></i></button>
            </div>
        </div>
    </div>



    <script>
        import { Color, Solver } from '/src/player_files/accessibility/image_color.js';
        // jQuery is now available in all project see webpack.config.js:126
        //import $ from 'jquery';
        export default {

            hexToRgb(hex) {
                const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
                hex = hex.replace(shorthandRegex, (m, r, g, b) => {
                    return r + r + g + g + b + b;
                });

                const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
                return result
                    ? [
                        parseInt(result[1], 16),
                        parseInt(result[2], 16),
                        parseInt(result[3], 16),
                    ]
                    : null;
            },

            applyFilters() {
                const hex = document.getElementById('tamkinPlayerApp').style.getPropertyValue('--tp-player-primary');
                const rgb = this.hexToRgb(hex);
                let fin;
                if (rgb && rgb.length === 3) {
                    const color = new Color(rgb[0], rgb[1], rgb[2]);
                    const solver = new Solver(color);
                    const result = solver.solve();

                    fin = result.filter;
                }
                return fin;
            },
            loadFile(path) {
                return 'https://tamkin.app' + path;
            },
            onMounted() {

                $(".icon-close-side").on("click", function () {
                    console.log("ok ");
                    console.log($(this).closest('.side-menu-card'))
                    $(this).closest('.side-menu-card').removeClass('active');
                    // $("#player-card #links-container").removeClass("active");
                    // $('#links-container .menu-link').removeClass("active");
                    $('#start-menu').off();
                });
                // should be outside $("#start-menu").on("click") just in case the icon-close-side is clicked before
                // #start-menu click then the coming function will not be executed
                $("#translation-tutorial-container .doneBtn, #translation-tutorial-container .icon-close-side").on("click", function () {
                    var $links = $('#links-container .menu-link');
                    $links.removeClass("active");
                    $("#links-container").removeClass("active");
                    $('#translation-tutorial-container').removeClass("active");
                    $("#translation-tutorial-container .nextBtn").show();
                    $("#translation-tutorial-container .doneBtn").hide();
                });

                $("#tutorial-config-btn").on("click", function () {

                    $("#tutorial-menu").removeClass("active");
                    $("#tutorial-config").addClass('active');
                    $("#links-container").hide();
                    $("#tutorial-content").hide();
                    $(".btn-tutorial").trigger('click'); // $(".tutorial-card").hide();
                    $(".btns-config").addClass("active").show();

                    var $cards = $('.item-menu-config'); // Select all cards
                    var $links = $('.btns-config .menu-link');
                    var currentIndexConfig = 0; // Index of the currently displayed card
                    var cardsContainer = "#tutorial-config";

                    // Display the first card initially
                    showCard(currentIndexConfig, $cards, $links, cardsContainer);
                    // Previous button click event handler
                    $('#tutorial-config .prevBtn').click(function () {
                        currentIndexConfig = currentIndexConfig > 0 ? currentIndexConfig - 1 : 0; // Decrement the index
                        showCard(currentIndexConfig, $cards, $links, cardsContainer);
                    });
                    // Next button click event handler
                    $('#tutorial-config .nextBtn').click(function () {
                        currentIndexConfig++;
                        showCard(currentIndexConfig, $cards, $links, cardsContainer);
                    });


                    $("#tutorial-config .doneBtn, #tutorial-config .icon-close-side").on("click", function () {
                        $links.removeClass("active");
                        $('#tutorial-menu').removeClass("active");
                        $('#tutorial-config').removeClass("active");
                        $(".btns-config").hide();
                        $("#links-container").show();
                        $("#tutorial-config .nextBtn").show();
                        $("#tutorial-config .doneBtn").hide();
                    });

                });

                $("#tutorial-info-btn").on("click", function () {

                    $("#tutorial-menu").removeClass("active");
                    $("#tutorial-info").addClass('active');
                    $("#links-container").hide();
                    $("#tutorial-content").hide();
                    $(".btn-tutorial").trigger('click'); // $(".tutorial-card").hide();
                    $(".btns-info").addClass("active").show();

                    var $cards = $('.item-menu-info'); // Select all cards
                    var $links = $('.btns-info .menu-link');
                    var currentIndexInfo = 0; // Index of the currently displayed card
                    var cardsContainer = "#tutorial-info";
                    // Display the first card initially
                    showCard(currentIndexInfo, $cards, $links, cardsContainer);
                    // Previous button click event handler
                    $('#tutorial-info .prevBtn').click(function () {
                        currentIndexInfo = currentIndexInfo > 0 ? currentIndexInfo - 1 : 0; // Decrement the index
                        showCard(currentIndexInfo, $cards, $links, cardsContainer);
                    });
                    // Next button click event handler
                    $('#tutorial-info .nextBtn').click(function () {
                        currentIndexInfo++;
                        showCard(currentIndexInfo, $cards, $links, cardsContainer);
                    });
                    $("#tutorial-info .doneBtn, #tutorial-info .icon-close-side").on("click", function () {
                        $links.removeClass("active");
                        $('#tutorial-menu').removeClass("active");
                        $('#tutorial-info').removeClass("active");
                        $(".btns-info").hide();
                        $("#links-container").show();
                        $("#tutorial-info .nextBtn").show();
                        $("#tutorial-info .doneBtn").hide();
                    });

                });

                // translation tutorial
                function showCard(index, cards, links, cardsContainer) {
                    var prevBtn = $(cardsContainer + ' .prevBtn')
                    if (index == 0) {
                        prevBtn.css('visibility', 'hidden');
                    } else {
                        prevBtn.css('visibility', 'visible');
                    }
                    if (index === cards.length - 1) {
                        $(cardsContainer + " .nextBtn").hide();
                        $(cardsContainer + " .doneBtn").show();
                        links.removeClass("active");
                    } else {
                        $(cardsContainer + " .doneBtn").hide();
                        $(cardsContainer + " .nextBtn").show();
                    }

                    // add highlighting border around icon close main in translation tutorial
                    if (index === cards.length - 2 && cardsContainer == '#translation-tutorial-container') {
                        $('#icon-close-main').addClass("tut-active");
                    } else {
                        if ($('#icon-close-main').hasClass("tut-active"))
                            $('#icon-close-main').removeClass("tut-active");
                    }

                    cards.hide(); // Hide all cards
                    cards.eq(index).show(); // Show the card at the specified index
                    links.removeClass('active'); // Remove "active" class from all links
                    links.eq(index).addClass('active'); // Add "active" class to the link at the specified index
                }

                $("#btn-tutorial-translation").on("click", function () {
                    $("#tutorial-content").hide();
                    $(".btn-tutorial").trigger('click'); // $(".tutorial-card").hide();
                    $('#translation-tutorial-container').addClass('active');
                    $("#player-card #links-container").addClass("active");

                    var $cards = $('.item-menu-translation'); // Select all cards
                    var $links = $('#links-container .menu-link');
                    var currentIndexTranslation = 0; // Index of the currently displayed card
                    var cardsContainer = "#translation-tutorial-container";
                    // Display the first card initially
                    showCard(currentIndexTranslation, $cards, $links, cardsContainer);
                    // Previous button click event handler
                    $('#translation-tutorial-container .prevBtn').click(function () {
                        currentIndexTranslation = currentIndexTranslation > 0 ? currentIndexTranslation - 1 : 0; // Decrement the index
                        showCard(currentIndexTranslation, $cards, $links, cardsContainer);
                    });
                    // Next button click event handler
                    $('#translation-tutorial-container .nextBtn').click(function () {
                        currentIndexTranslation++;
                        showCard(currentIndexTranslation, $cards, $links, cardsContainer);
                    });

                });
            }
        }

    </script>


</Side-test>