<main-component>

    <div>
        <div class="player-icon-container" if="{state.enablePlayer == true}">
            <div class="tp-hero-talk">
                <img src={loader} id="image_pricipal">
            </div>
            <div class="tp-hero-talk-text">
                { (state.player_settings?.icon_text) }
            </div>
        </div>

        <div if="{state.enablePlayer == true}" class="tp-talk-card" id="player-card">
            <img style="{applyFilters()}" width="25" src="{window.tamkin_src_base+'/images/close.svg'}"
                class="icon-close" id="icon-close-main">

            <div class="talk-content">
                <div id="loader" class="when-empty-card">
                    <lottie-player src={createBlob(state.handload)} background="transparent" speed="1" direction="1"
                        mode="normal" loop autoplay></lottie-player>
                    <div class="text-develop-container">
                        <a target="_blank" href={state.player_settings.developed_by_url} class="text-develop">{
                            (state.player_settings?.developed_by_text) } <img width="15" height="15"
                                style="{applyFilters()} ;margin-top: 3px"
                                src={loadFile(state.player_settings.button_tamkin_icon)}>
                        </a>
                    </div>
                </div>
                <div id="loader-hand">
                    <lottie-player src={createBlob(state.twohand)} background="transparent" speed="1" direction="1"
                        mode="normal" loop autoplay></lottie-player>
                </div>
                <div class="man-container">
                    <tamkin-sdk if="{state.playerBackground && state.playerContrast && state.playerContrastIconInit
                             && state.playerContrastIconReplaced }" playerBackground="{state.playerBackground}"
                        playerContrast="{state.playerContrast}"
                        playerContrastIconReplaced="{state.playerContrastIconReplaced}"
                        playerContrastIconInit="{state.playerContrastIconInit}"
                        playerHighlight="{state.playerHighlight}"></tamkin-sdk>
                </div>
                <div class="btns-action" id="links-container">
                    <div id="loader-get-translation" class="">
                        <lottie-player src={createBlob(state.loading)} background="transparent" speed="1" direction="1"
                            mode="normal" loop autoplay></lottie-player>
                    </div>

                    <span class="menu-link just-a-placeholder"></span>

                    <span class="menu-link btn-play" id="playButt">
                        <img id="init_icon" src={loadFile(state.player_settings.play_grey_icon)}>
                        <img style="{applyFilters()}" id="play_icon"
                            src={loadFile(state.player_settings.play_grey_icon)}>
                        <img style="{applyFilters()}" id="pause_icon" src={loadFile(state.player_settings.pause_icon)}>
                    </span>
                    <span class="menu-link btn-rate-translate">
                        <img id="icon-rate-before" src={loadFile(state.player_settings?.rate_grey_icon)}>
                        <img id="icon-rate-after" src={loadFile(state.player_settings.rate_icon)}
                            style="{applyFilters()}">
                    </span>
                    <span class="menu-link btn-change-speed">
                        <span>1.0</span>
                    </span>
                    <span class="menu-link btn-tutorial">
                        <img id="open-tutorial" src={loadFile(state.player_settings.learn_icon)} class="tamkin-logo"
                            style="{applyFilters()}">
                        <img id="close-tutorial" src={loadFile(state.player_settings.close_icon)}
                            style="{applyFilters()}">
                    </span>
                    <span class="menu-link btn-settings">
                        <img id="open-settings" src={loadFile(state.player_settings.settings_icon)}
                            style="{applyFilters()}">
                        <img id="close-settings" src={loadFile(state.player_settings.close_icon)}
                            style="{applyFilters()}">
                    </span>
                </div>
                <div class="btns-config" id="config-btns-id" style="display: none;">
                    <span class="menu-link just-a-placeholder"></span>
                    <span class="menu-link">
                        <img style="{applyFilters()}" src={loadFile(state.player_settings.contrast_day_icon)}>
                    </span>
                    <span class="menu-link">
                        <img style="{applyFilters()}" src={loadFile(state.player_settings.view_icon)}>
                    </span>
                    <span class="menu-link">
                        <img style="{applyFilters()}" src={loadFile(state.player_settings.position_icon)}>
                    </span>
                    <span class="menu-link">
                        <img style="{applyFilters()}" src={loadFile(state.player_settings.keyboard_icon)}>
                    </span>
                </div>

                <div class="btns-info" id="info-btns-id" style="display: none;">
                    <span class="menu-link just-a-placeholder"></span>
                    <span class="menu-link">
                        <img style="{applyFilters()}" src={loadFile(state.player_settings.tutorial_icon)}>
                    </span>
                    <span class="menu-link">
                        <img style="{applyFilters()}" src={loadFile(state.player_settings.about_icon)}>
                    </span>
                </div>

                <div class="change-speed-card animated">
                    { ("Speed:") } <br> <span></span>
                </div>
                <div class="tutorial-card animated" id="tutorial-card-id" style="display: none;">
                    <div class="translate-item" id="tutorial">
                        <div class="icon"><img style="{applyFilters()}"
                                src={loadFile(state.player_settings.tutorial_icon)}></div>
                        { (state.player_settings?.tutorial_text) }
                    </div>
                    <div class="translate-item" id="about">
                        <div class="icon"><img height="25" style="{applyFilters()}"
                                src={loadFile(state.player_settings.about_icon)}></div>
                        { (state.player_settings?.about_text) }
                    </div>
                </div>

                <div class="settings-card animated" id="settings-card-id" style="display: none;">
                    <div class="settings-item" id="set-contrast" if="{state.enableContrast}">
                        <div class="icon"><img style="{applyFilters()}" src={loadFile(state.playerContrastIconInit)}>
                        </div>
                        Contrast
                    </div>
                    <div class="settings-item" id="set-background" if="{state.enableBackground}">
                        <div class="icon"><img style="{applyFilters()}" src={loadFile(state.playerBackgroundIcon)}>
                        </div>
                        View
                    </div>
                    <div class="settings-item" id="set-position" if="{state.enablePosition}">
                        <div class="icon"><img style="{applyFilters()}" src={loadFile(state.playerModeIconLeft)}></div>
                        Position
                    </div>
                    <div class="settings-item" id="set-keyboard" if="{state.enableKeyboard}">
                        <input type="text" style="display: none;" id="tamkinInitialKb">
                        <div class="icon"><img style="{applyFilters()}"
                                src={loadFile(state.player_settings?.keyboard_icon)}></div>
                        Keyboard
                    </div>

                </div>

                <div class="change-background-card animated"></div>

            </div>
            <div class="about-card animated" id="about-content" style="display: none;">
                <img width="25" style="{applyFilters()}" src="{window.tamkin_src_base+'/images/close.svg'}"
                    class="icon-close" id="icon-close-about">
                <div class="icon">
                    <img style="{applyFilters()}" src={loadFile(state.player_settings.about_top_icon)}
                        class="tamkin-logo">
                </div>
                <h3 class="text-main">{ (state.player_settings?.about_title) }</h3>
                <p>{ (state.player_settings?.about_description) }</p>
                <div class="btns-about-bottom asl-link">
                    <button class="btn btn-bg btn-asl">
                        <img src={loadFile(state.player_settings?.button_asl_icon)}>
                        { (state.player_settings?.button_asl_text) }
                    </button>
                    <a target="_blank" href={state.player_settings.developed_by_url} class="btn btn-border btn-tamkin">
                        <img width="40" style="{applyFilters()}"
                            src={loadFile(state.player_settings.button_tamkin_icon)}>
                        { (state.player_settings?.button_tamkin_text) }
                    </a>
                </div>
            </div>
            <div class="about-card animated" id="tutorial-content" style="display: none;">
                <img style="{applyFilters()}" width="25" src="{window.tamkin_src_base+'/images/close.svg'}"
                    class="icon-close" id="icon-close-tutorial">
                <div class="icon">
                    <img style="{applyFilters()}" src={loadFile(state.player_settings?.tutorial_top_icon)}
                        class="tamkin-logo">
                </div>
                <h3>{ (state.player_settings?.tutorial_title) }</h3>
                <p style="margin-bottom: 40px;">{ (state.player_settings?.tutorial_description) }</p>
                <div class="btns-about-bottom menu-trans">
                    <button class="btn btn-bg" id="btn-tutorial-menu">
                        <img src={loadFile(state.player_settings?.button_menu_icon)}> {
                        (state.player_settings?.button_menu_text) }
                    </button>
                    <button class="btn btn-bg" id="btn-tutorial-translation">
                        <img src={loadFile(state.player_settings?.button_translation_icon)}> {
                        (state.player_settings?.button_translation_text) }
                    </button>

                </div>
            </div>
            <div class="about-card animated" id="rate-translate-content" style="display: none;">
                <img style="{applyFilters()}" width="25" src="{window.tamkin_src_base+'/images/close.svg'}"
                    class="icon-close" id="icon-close-rate">
                <div class="icon">
                    <img style="{applyFilters()}" src={loadFile(state.player_settings?.rating_top_icon)}
                        class="tamkin-logo">
                </div>
                <h3>{ (state.player_settings?.rating_title) }</h3>
                <p>{ (state.player_settings?.rating_description) }</p>
                <div class="rate-translate">
                    <div class="check-rate">
                        <div class="item">
                            <input type="radio" class="btn-check" name="options" id="option1" autocomplete="off">
                            <label class="btn" for="option1">
                                <img class="rate-un" src={loadFile(state.player_settings?.poor_grey_icon)}>
                                <img style="{applyFilters()}" class="rate-hover"
                                    src={loadFile(state.player_settings?.poor_icon)}>
                                <p>{ (state.player_settings?.poor_text) }</p>
                            </label>
                        </div>
                        <div class="item">
                            <input type="radio" class="btn-check" name="options" id="option2" autocomplete="off">
                            <label class="btn" for="option2">
                                <img class="rate-un" src={loadFile(state.player_settings?.good_grey_icon)}>
                                <img style="{applyFilters()}" class="rate-hover"
                                    src={loadFile(state.player_settings?.good_icon)}>
                                <p>{ (state.player_settings?.good_text) }</p>
                            </label>
                        </div>

                    </div>
                    <button class="btn btn-bg mt-3" id="confirm-rate">
                        { (state.player_settings?.rating_button_text)}
                    </button>
                </div>

            </div>

            <div class="" id="finish-card">
                <div id="loader-finish-rate" class="">
                    <lottie-player src={createBlob(state.hearts)} background="transparent" speed="1" direction="1"
                        mode="normal" loop autoplay></lottie-player>
                </div>
                <div class="about-card" id="finish-rate">
                    <p>{ (state.player_settings?.rating_thanks_text) }</p>
                </div>
            </div>

            <side-menu message="{ state.player_settings }"></side-menu>
        </div>

        <accessibility-full-widget accessibility_icon="{ state.accessibility_icon}"
            sidebarPosition="{ state.sidebarPosition }"
            if="{state.enableWidegt && state.accessibility_type == 'full-widget'}"
            accessibility_settings="{ state.player_settings }">
        </accessibility-full-widget>
        <accessibility-mini-widget accessibility_icon="{state.accessibility_icon}"
            sidebarPosition="{ state.sidebarPosition }"
            if="{state.enableWidegt && state.accessibility_type == 'mini-widget'}"
            accessibility_settings="{ state.player_settings }">
        </accessibility-mini-widget>

        <accessibility-minuscule-widget accessibility_icon="{state.accessibility_icon}"
            sidebarPosition="{ state.sidebarPosition }"
            if="{state.enableWidegt && state.accessibility_type == 'minuscule-widget'}"
            accessibility_settings="{ state.player_settings }">
        </accessibility-minuscule-widget>

        <accessibility-round-widget accessibility_icon="{state.accessibility_icon}"
            sidebarPosition="{ state.sidebarPosition }"
            if="{state.enableWidegt && state.accessibility_type == 'round-widget'}"
            accessibility_settings="{ state.player_settings }">
        </accessibility-round-widget>

        <language-widget mainlanguages="{state.languages}" widgetType="{state.accessibility_type}"
            sidebarPosition="{ state.sidebarPosition }" langModeIcon="{state.langModeIcon}"
            if="{ state.showLang == true && state.sidebarPosition !== 'hide'}"
            accessibility_settings="{ state.player_settings }">
        </language-widget>

    </div>



    <script>
        // jQuery is now available in all project see webpack.config.js:126
        //import $ from 'jquery';
        //window.jQuery = window.$ = $;
        //jQuery.noConflict();
        window.tamkin_src_base = "";
        if (process.env.NODE_ENV === "production") {
            window.tamkin_src_base = "https://cdn.tamkin.app";
        }
        const importAllImages = (requireContext) => requireContext.keys().map(requireContext);
        const images = importAllImages(require.context('/src/player_files/accessibility/img', false, /\.(png|jpe?g|svg)$/));
        import fileAudio from "/src/player_files/audio/open_audio.mp3";
        const sound = window.tamkin_src_base + fileAudio;
        import "@lottiefiles/lottie-player";
        import loader from "./player_files/images/h.svg"

        import SideMenu from './Side-Menu.riot'
        import handload from './player_files/json/handload.json';
        import hearts from './player_files/json/hearts.json';
        import loading from './player_files/json/loading.json';
        import twohand from './player_files/json/twohand.json';
        import AccessibilityFullWidget from '/src/accessibility/full_widget/accessibility.riot';
        import AccessibilityMiniWidget from '/src/accessibility/mini_widget/accessibility.riot';
        import AccessibilityMinusculeWidget from '/src/accessibility/minuscule_widget/accessibility.riot';
        import AccessibilityRoundWidget from '/src/accessibility/round_widget/accessibility.riot';
        import LanguageWidget from '/src/accessibility/language_widget/lang.riot';
        import tinycolor from "tinycolor2";
        import { Color, Solver } from '/src/player_files/accessibility/image_color.js';

        import TaminSDK from './tamkin-sdk.riot';
        const componentElement = document.querySelector('tamkin-player-sdk');
        let version;
        if (componentElement) {
            version = componentElement.getAttribute('with-player');
        }


        window.onload = function () {
            localStorage.removeItem("lang")
        };


        export default {
            components: {
                'tamkin-sdk': TaminSDK,
                'Side-Menu': SideMenu,
                'accessibility-full-widget': AccessibilityFullWidget,
                'accessibility-mini-widget': AccessibilityMiniWidget,
                'accessibility-minuscule-widget': AccessibilityMinusculeWidget,
                'accessibility-round-widget': AccessibilityRoundWidget,
                'language-widget': LanguageWidget,
            },
            state: {
                player_settings: {},
                with_player: version,
                handload: handload,
                hearts: hearts,
                loading: loading,
                twohand: twohand,
                accessibility_type: '',
                showLang: false,
                languageFeatures: {},
                enabledFeatures: [],
                sidebarPosition: '',
                langModeIcon: '',
                accessibility_icon: `./player_files/accessibility/img/accessibility.svg`,
                enableWidegt: false,
                enablePlayer: false,
                enableColor2: false,
                enablePlayerColor2: false,
                languages: [],
                playerModeIconRight: '',
                playerModeIconLeft: '',
                playerBackground: '',
                playerBackgroundIcon: '',
                playerContrastIconInit: '',
                playerContrastIconReplaced: '',
                playerContrast: '',
                playerHighlight: false,
                enableKeyboard: false,
                enableBackground: false,
                enableContrast: false,
                enablePosition: false


            },
            loadFile(path) {
                return 'https://tamkin.app' + path;
            },

            createBlob(json) {

                const blob = new Blob([JSON.stringify(json)], { type: 'application/json' });
                // Create a URL object from the Blob
                const url = URL.createObjectURL(blob);
                return url;
            },



            onBeforeMount(props, state) {
                const tamkinPlayerApp = document.getElementById('tamkinPlayerApp');
                fetch('https://api.tamkin.app/v1/api/Widget/GetAccessibility')
                    .then(response => {
                        if (!response.ok) {
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        }
                        return response.json();
                    })
                    .then(data => {
                        this.update({
                            languages: data.data.languages,
                            sidebarPosition: data.data.features.find(feature => feature.name === 'acc-customize-accessibility-mode')?.features[0]?.value,
                            enabledFeatures: data.data.features,
                            languageFeatures: data.data.features.find(feature => feature.name === 'acc-customize-translations-button')?.features,
                            accessibility_icon: this.loadFile(data.data.features.find(feature => feature.name === 'acc-customize-button-type').features.
                                find(feature => feature.name === "acc-customize-button-type-button-shape").tamkin_option_item_values
                                .find(el => el.value == data.data.features.find(feature => feature.name === 'acc-customize-button-type').features.
                                    find(feature => feature.name === "acc-customize-button-type-button-shape").value).icon),
                            accessibility_type: data.data.features.find(feature => feature.name === 'acc-customize-widget-type')?.features.
                                find(feature => feature.name === "acc-customize-widget-type-widget-style").value,
                        });


                        // hadle lang icon 
                        if (this.state.languageFeatures.length) {
                            const inOrout = this.state.languageFeatures.find((feature) => feature.name === 'acc-customize-translations-button-position-translation-button-above');
                            const activeor = this.state.languageFeatures.find((feature) => feature.name === 'acc-customize-translations-button-enable-live-site-translations-button');
                            if (activeor.value == 1 && activeor.active == 1 && inOrout?.is_selected == '1') {
                                this.update({
                                    showLang: true,
                                    langModeIcon: inOrout?.value
                                });
                            }
                        }
                        // }, 4000);
                        this.update();

                        if (!localStorage.getItem("tpSidebarPosition")) {

                            // if (!localStorage.getItem("tpSidebarPosition")) {|| data.data.features.find(feature => feature.name === 'acc-customize-accessibility-mode')?.features[0]?.value !== 'hide'

                            localStorage.setItem("tpSidebarPosition", data.data.features.find(feature => feature.name === 'acc-customize-accessibility-mode')?.features[0]?.value)
                        }



                        // set colors 
                        const color1 = data.data.features.find(feature => feature.name === 'acc-customize-button-color')?.features[0].value.split(',')[0];
                        const color2 = data.data.features.find(feature => feature.name === 'acc-customize-button-color')?.features[0].value.split(',')[1];
                        tamkinPlayerApp.style.setProperty('--tp-primary', color1);

                        if (color2) {
                            if (color1 === color2) {
                                let lighterColor = tinycolor(color1).setAlpha(0.2).toString();
                                tamkinPlayerApp.style.setProperty('--tp-primary-grad', lighterColor);
                                tamkinPlayerApp.style.setProperty('--tp-primary-light', lighterColor);
                                $(".highlight").css("backgroundColor", lighterColor)
                                this.update({ enableColor2: true })
                            } else {
                                tamkinPlayerApp.style.setProperty('--tp-primary-grad', color2);
                                tamkinPlayerApp.style.setProperty('--tp-primary-light', color2);
                                $(".highlight").css("backgroundColor", color2)
                                this.update({ enableColor2: true })
                            }
                        } else {
                            let lighterColor = tinycolor(color1).setAlpha(0.2).toString();
                            tamkinPlayerApp.style.setProperty('--tp-primary-grad', lighterColor);
                            tamkinPlayerApp.style.setProperty('--tp-primary-light', lighterColor);
                            $(".highlight").css("backgroundColor", lighterColor);
                        }
                        // hadle size of widget 
                        const size = data.data.features.find(feature => feature.name === 'acc-customize-button-type')?.features.find(el => el.name == 'acc-customize-button-type-button-size').value;
                        tamkinPlayerApp.style.setProperty('--tp-accessibility-icon-width', `${size}px`);
                        tamkinPlayerApp.style.setProperty('--tp-accessibility-icon-height', `${size}px`);
                        tamkinPlayerApp.style.setProperty('--tp-accessibility2-icon-width', `${size}px`);
                        tamkinPlayerApp.style.setProperty('--tp-accessibility2-icon-height', `${size}px`);
                        tamkinPlayerApp.style.setProperty('--tp-accessibility3-icon-width', `${size}px`);
                        tamkinPlayerApp.style.setProperty('--tp-accessibility3-icon-height', `${size}px`);
                        tamkinPlayerApp.style.setProperty('--tp-accessibility4-icon-width', `${size}px`);
                        tamkinPlayerApp.style.setProperty('--tp-accessibility4-icon-height', `${size}px`);
                        $(".iconCode").css("fontSize", `${size / 2}px`)



                        // sound effect 
                        const enableSoundEffect = data.data.features.find(feature => feature.name === 'acc-setting-general-settings')?.features
                            .find(el => el.name === 'acc-setting-general-settings-sound-effects');
                        if (enableSoundEffect.active == 1 && enableSoundEffect.value == 1) {
                            localStorage.removeItem("enableSoundEffect")
                            localStorage.setItem('enableSoundEffect', 1)
                        } else {
                            localStorage.removeItem("enableSoundEffect")
                        }



                        if (window.visualViewport.width > 500) {
                            const desktopSetting = data.data.features.find(feature => feature.name === 'acc-setting-general-settings')?.features
                                .find(el => el.name === 'acc-setting-general-settings-widget-enabled-on-this-site');

                            if (desktopSetting?.active == 1 && desktopSetting?.value == 1) {
                                this.update({ enableWidegt: true })
                            }

                            // enable player or not
                            const desktopPlayerSetting = data.data.features.find(feature => feature.name === 'deaf-setting-general-settings')?.features
                                .find(el => el.name === 'deaf-setting-general-settings-player-enabled-on-this-site');
                            if (desktopPlayerSetting?.active == 1 && desktopPlayerSetting?.value == 1) {
                                this.update({ enablePlayer: true })
                            }

                            this.update();


                        } else {
                            const mobileSetting = data.data.features.find(feature => feature.name === 'acc-setting-general-settings')?.features
                                .find(el => el.name === 'acc-setting-general-settings-widget-enabled-on-mobile');
                            if (mobileSetting?.active == 1 && mobileSetting?.value == 1) {
                                this.update({ enableWidegt: true })
                            }

                            // enable player or not
                            const mobilePlayerSetting = data.data.features.find(feature => feature.name === 'deaf-setting-general-settings')?.features
                                .find(el => el.name === 'deaf-setting-general-settings-player-enabled-on-this-mobile');
                            if (mobilePlayerSetting?.active == 1 && mobilePlayerSetting?.value == 1) {
                                this.update({ enablePlayer: true })
                            }
                        }

                        this.makePlayerOn();




                        // handle tamkin player 
                        // button shape 
                        document.getElementById('image_pricipal').src = this.loadFile(data.data.features.find(feature => feature.name === 'deaf-customize-button-type').features.
                            find(feature => feature.name === "deaf-customize-button-type-sign-language-button-shape").tamkin_option_item_values
                            .find(el => el.value === data.data.features.find(feature => feature.name === 'deaf-customize-button-type').features.
                                find(feature => feature.name === "deaf-customize-button-type-sign-language-button-shape").value).icon);

                        // set colors 
                        const playercolor1 = data.data.features.find(feature => feature.name === 'deaf-customize-button-color')?.features.
                            find(el => el.name === "deaf-customize-button-color-sign-language-button-color").value.split(',')[0];
                        const playercolor2 = data.data.features.find(feature => feature.name === 'deaf-customize-button-color')?.features.
                            find(el => el.name === "deaf-customize-button-color-sign-language-button-color").value.split(',')[1];
                        tamkinPlayerApp.style.setProperty('--tp-player-primary', playercolor1);


                        if (playercolor2) {

                            if (playercolor1 === playercolor2) {
                                let lighterColor = tinycolor(playercolor1).setAlpha(0.2).toString();
                                tamkinPlayerApp.style.setProperty('--tp-player-primary-grad', lighterColor);
                                this.update({ enablePlayerColor2: true })

                            } else {
                                tamkinPlayerApp.style.setProperty('--tp-player-primary-grad', playercolor2);
                                this.update({ enablePlayerColor2: true })
                            }
                        } else {
                            let lighterColor = tinycolor(playercolor1).setAlpha(0.2).toString();
                            tamkinPlayerApp.style.setProperty('--tp-player-primary-grad', lighterColor);

                        }

                        this.update();
                        // hadle size of img icon player 
                        const playersize = data.data.features.find(feature => feature.name === 'deaf-customize-button-type')?.features
                            .find(el => el.name == 'deaf-customize-button-type-sign-language-player-button-size').value;


                        tamkinPlayerApp.style.setProperty('--tp-player-icon-width', `${playersize}px`);
                        tamkinPlayerApp.style.setProperty('--tp-player-icon-height', `${playersize}px`);

                        this.update();

                        // sound effect in player 
                        const enableSoundEffectInPlayer = data.data.features.find(feature => feature.name === 'deaf-setting-general-settings')?.features
                            .find(el => el.name === 'deaf-setting-general-settings-player-sound-effects');
                        if (enableSoundEffectInPlayer.active == 1 && enableSoundEffectInPlayer.value == 1) {
                            localStorage.removeItem("enableSoundEffectInPlayer")
                            localStorage.setItem('enableSoundEffectInPlayer', 1)
                        } else {
                            localStorage.removeItem("enableSoundEffectInPlayer")
                        }

                        const checkplayerHighlight = data.data.features.find(feature => feature.name === "deaf-customize-sign-language-player-language")?.features
                            .find(el => el.name === "deaf-customize-sign-language-player-language-sign-language-show-language-selector-on-the-widget");

                        if (checkplayerHighlight.value == 1 && checkplayerHighlight.active == 1) {
                            this.state.playerHighlight = true;
                            this.update()
                        }

                        // handle mode
                        this.update({
                            enablePosition: data.data.features.find(feature => feature.name === 'deaf-customize-sign-language-mode')?.features
                                .find(el => el.name == 'deaf-customize-sign-language-mode-move-/-hide-sign-language-player').active == 1 ? true : false
                        })
                        const playerMode = data.data.features.find(feature => feature.name === 'deaf-customize-sign-language-mode')?.features
                            .find(el => el.name == 'deaf-customize-sign-language-mode-move-/-hide-sign-language-player').value;
                        this.update({
                            playerModeIconRight: data.data.features.find(feature => feature.name === 'deaf-customize-sign-language-mode')?.features
                                .find(el => el.name == 'deaf-customize-sign-language-mode-move-/-hide-sign-language-player').tamkin_option_item_values.find(el => el.value === 'right').icon
                        });
                        this.update({
                            playerModeIconLeft: data.data.features.find(feature => feature.name === 'deaf-customize-sign-language-mode')?.features
                                .find(el => el.name == 'deaf-customize-sign-language-mode-move-/-hide-sign-language-player').tamkin_option_item_values.find(el => el.value === 'left').icon
                        });

                        if (playerMode === 'hide') {
                            this.update({ enablePlayer: false })
                        } else {
                            if (localStorage.getItem("player_position")) {
                                if (localStorage.getItem("player_position") === 'left') {
                                    $("#player-card").addClass("left");
                                    $(".tp-hero-talk").addClass("left");
                                    $(".tp-hero-talk-text").addClass("left");
                                } else {
                                    $("#player-card").removeClass("left");
                                    $(".tp-hero-talk").removeClass("left");
                                    $(".tp-hero-talk-text").removeClass("left");
                                }
                            } else {
                                if (playerMode === 'left') {
                                    $("#player-card").addClass("left");
                                    $(".tp-hero-talk").addClass("left");
                                    $(".tp-hero-talk-text").addClass("left");

                                    if (!localStorage.getItem("player_position")) {
                                        localStorage.setItem("player_position", "left");
                                    }
                                } else {
                                    if (!localStorage.getItem("player_position")) {
                                        localStorage.setItem("player_position", "right");
                                    }
                                }
                            }
                        }

                        // set player Background 
                        this.update({
                            enableBackground: data.data.features.find(feature => feature.name === 'deaf-customize-sign-language-player-background')?.features
                                .find(el => el.name == 'deaf-customize-sign-language-background-sign-language-background').active == 1 ? true : false
                        })
                        this.update({
                            playerBackground: data.data.features.find(feature => feature.name === 'deaf-customize-sign-language-player-background')?.features
                                .find(el => el.name == 'deaf-customize-sign-language-background-sign-language-background').value,
                            playerBackgroundIcon: data.data.features.find(feature => feature.name === 'deaf-customize-sign-language-player-background')?.features
                                .find(el => el.name == 'deaf-customize-sign-language-background-sign-language-background').icon
                        })

                        // set player contrast 
                        this.update({
                            enableContrast: data.data.features.find(feature => feature.name === 'deaf-customize-sign-language-player-contrast')?.features
                                .find(el => el.name == 'deaf-customize-sign-language-player-contrast-sign-language-contrast').active == 1 ? true : false
                        })
                        this.state.playerContrast = data.data.features.find(feature => feature.name === 'deaf-customize-sign-language-player-contrast')?.features
                            .find(el => el.name == 'deaf-customize-sign-language-player-contrast-sign-language-contrast').value;
                        this.state.playerContrastIconInit = data.data.features.find(feature => feature.name === 'deaf-customize-sign-language-player-contrast')?.features
                            .find(el => el.name == 'deaf-customize-sign-language-player-contrast-sign-language-contrast').tamkin_option_item_values.find(el => el.value === this.state.playerContrast).icon;
                        this.state.playerContrastIconReplaced = data.data.features.find(feature => feature.name === 'deaf-customize-sign-language-player-contrast')?.features
                            .find(el => el.name == 'deaf-customize-sign-language-player-contrast-sign-language-contrast').tamkin_option_item_values.find(el => el.value !== this.state.playerContrast).icon;

                        this.update({
                            enableKeyboard: data.data.features.find(feature => feature.name === "deaf-customize-sign-language-player-keyboard")?.features
                                .find(el => el.name == "deaf-customize-sign-language-player-keyboard-sign-language-keyboard").active == 1 ? true : false
                        })


                        const getPlayerLang = data.data.features.find(feature => feature.name === 'deaf-customize-sign-language-player-language')?.features
                            .find(el => el.name == 'deaf-customize-sign-language-list-list-sign-language').value;

                        if (getPlayerLang === 'auto detect language') {
                            fetch('https://api.tamkin.app/v1/api/Widget/LanguageDetector', {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify({
                                    text: document.title
                                })
                            })
                                .then(response => {
                                    if (!response.ok) {
                                        throw new Error(`HTTP error! Status: ${response.status}`);
                                    }
                                    return response.json();
                                })
                                .then(data => {
                                    if (data.data) {
                                        localStorage.setItem("player_lang", data.data || 'en')
                                    }
                                })
                                .catch(error => {
                                    console.error('Fetch error:', error);
                                });
                        } else {
                            localStorage.setItem("player_lang", getPlayerLang || 'en')
                        }
                        // widget location 
                        if (window.visualViewport.width > 500) {

                            const obj = data.data.features.
                                find(feature => feature.name === "deaf-customize-button-location")
                                ?.features.find(el => el.name == "deaf-customize-button-location-sign-language-button-location-desktop");

                            const desktopLocation = obj.value
                            const tamkinPlayerApp = document.getElementById('tamkinPlayerApp');
                            if (obj.active == 1) {
                                if (desktopLocation === 'postion1') {
                                    if (localStorage.getItem("player_position") === 'left') {
                                        $("#player-card").addClass("left");
                                        $(".tp-hero-talk").addClass("left");
                                        $(".tp-hero-talk-text").addClass("left");
                                    } else {
                                        $("#player-card").removeClass("left");
                                        $(".tp-hero-talk").removeClass("left");
                                        $(".tp-hero-talk-text").removeClass("left");
                                    }
                                    tamkinPlayerApp.style.setProperty('--tp-player-icon-top', '30%');
                                } else if (desktopLocation === 'postion2') {
                                    if (localStorage.getItem("player_position") === 'left') {
                                        $("#player-card").addClass("left");
                                        $(".tp-hero-talk").addClass("left");
                                        $(".tp-hero-talk-text").addClass("left");
                                    } else {
                                        $("#player-card").removeClass("left");
                                        $(".tp-hero-talk").removeClass("left");
                                        $(".tp-hero-talk-text").removeClass("left");
                                    }
                                    tamkinPlayerApp.style.setProperty('--tp-player-icon-top', '60%');
                                } else if (desktopLocation === 'postion3') {
                                    if (localStorage.getItem("player_position") === 'left') {
                                        $("#player-card").addClass("left");
                                        $(".tp-hero-talk").addClass("left");
                                        $(".tp-hero-talk-text").addClass("left");
                                    } else {
                                        $("#player-card").removeClass("left");
                                        $(".tp-hero-talk").removeClass("left");
                                        $(".tp-hero-talk-text").removeClass("left");
                                    }
                                    tamkinPlayerApp.style.setProperty('--tp-player-icon-top', '90%');
                                } else if (desktopLocation === 'postion4') {
                                    $("#player-card").removeClass("left");
                                    $(".tp-hero-talk").removeClass("left");
                                    $(".tp-hero-talk-text").removeClass("left");
                                    $("#tamkinPlayerApp  .tp-hero-talk").css('right', '48%')
                                    $("#tamkinPlayerApp .tp-hero-talk-text").css('display', 'none')
                                    tamkinPlayerApp.style.setProperty('--tp-player-icon-top', '30%');
                                } else if (desktopLocation === 'postion5') {
                                    $("#player-card").removeClass("left");
                                    $(".tp-hero-talk").removeClass("left");
                                    $(".tp-hero-talk-text").removeClass("left");
                                    $("#tamkinPlayerApp  .tp-hero-talk").css('right', '48%')
                                    $("#tamkinPlayerApp .tp-hero-talk-text").css('display', 'none')
                                    tamkinPlayerApp.style.setProperty('--tp-player-icon-top', '90%');
                                } else if (desktopLocation === 'postion6') {
                                    if (localStorage.getItem("player_position") === 'right') {
                                        $("#player-card").removeClass("left");
                                        $(".tp-hero-talk").removeClass("left");
                                        $(".tp-hero-talk-text").removeClass("left");
                                    } else {
                                        $("#player-card").addClass("left");
                                        $(".tp-hero-talk").addClass("left");
                                        $(".tp-hero-talk-text").addClass("left");
                                    }
                                    tamkinPlayerApp.style.setProperty('--tp-player-icon-top', '30%');
                                } else if (desktopLocation === 'postion7') {
                                    if (localStorage.getItem("player_position") === 'right') {
                                        $("#player-card").removeClass("left");
                                        $(".tp-hero-talk").removeClass("left");
                                        $(".tp-hero-talk-text").removeClass("left");
                                    } else {
                                        $("#player-card").addClass("left");
                                        $(".tp-hero-talk").addClass("left");
                                        $(".tp-hero-talk-text").addClass("left");
                                    }
                                    tamkinPlayerApp.style.setProperty('--tp-player-icon-top', '60%');
                                } else if (desktopLocation === 'postion8') {
                                    if (localStorage.getItem("player_position") === 'right') {
                                        $("#player-card").removeClass("left");
                                        $(".tp-hero-talk").removeClass("left");
                                        $(".tp-hero-talk-text").removeClass("left");
                                    } else {
                                        $("#player-card").addClass("left");
                                        $(".tp-hero-talk").addClass("left");
                                        $(".tp-hero-talk-text").addClass("left");
                                    }
                                    tamkinPlayerApp.style.setProperty('--tp-player-icon-top', '90%');
                                }
                            }

                        } else {
                            const obj = data.data.features.
                                find(feature => feature.name === "deaf-customize-button-location")
                                ?.features.find(el => el.name == "deaf-customize-button-location-sign-language-button-location-mobile");
                            const mobileLocation = obj.value;

                            const tamkinPlayerApp = document.getElementById('tamkinPlayerApp');
                            if (obj.active == 1) {
                                if (mobileLocation === 'postion1') {
                                    if (localStorage.getItem("player_position") === 'left') {
                                        $("#player-card").addClass("left");
                                        $(".tp-hero-talk").addClass("left");
                                        $(".tp-hero-talk-text").addClass("left");
                                    } else {
                                        $("#player-card").removeClass("left");
                                        $(".tp-hero-talk").removeClass("left");
                                        $(".tp-hero-talk-text").removeClass("left");
                                    }
                                    tamkinPlayerApp.style.setProperty('--tp-player-icon-top', '30%');
                                } else if (mobileLocation === 'postion2') {
                                    if (localStorage.getItem("player_position") === 'left') {
                                        $("#player-card").addClass("left");
                                        $(".tp-hero-talk").addClass("left");
                                        $(".tp-hero-talk-text").addClass("left");
                                    } else {
                                        $("#player-card").removeClass("left");
                                        $(".tp-hero-talk").removeClass("left");
                                        $(".tp-hero-talk-text").removeClass("left");
                                    }
                                    tamkinPlayerApp.style.setProperty('--tp-player-icon-top', '60%');
                                } else if (mobileLocation === 'postion3') {
                                    if (localStorage.getItem("player_position") === 'left') {
                                        $("#player-card").addClass("left");
                                        $(".tp-hero-talk").addClass("left");
                                        $(".tp-hero-talk-text").addClass("left");
                                    } else {
                                        $("#player-card").removeClass("left");
                                        $(".tp-hero-talk").removeClass("left");
                                        $(".tp-hero-talk-text").removeClass("left");
                                    }
                                    tamkinPlayerApp.style.setProperty('--tp-player-icon-top', '90%');
                                } else if (mobileLocation === 'postion4') {
                                    if (localStorage.getItem("player_position") === 'right') {
                                        $("#player-card").removeClass("left");
                                        $(".tp-hero-talk").removeClass("left");
                                        $(".tp-hero-talk-text").removeClass("left");
                                    } else {
                                        $("#player-card").addClass("left");
                                        $(".tp-hero-talk").addClass("left");
                                        $(".tp-hero-talk-text").addClass("left");
                                    }
                                    tamkinPlayerApp.style.setProperty('--tp-player-icon-top', '30%');
                                } else if (mobileLocation === 'postion5') {
                                    if (localStorage.getItem("player_position") === 'right') {
                                        $("#player-card").removeClass("left");
                                        $(".tp-hero-talk").removeClass("left");
                                        $(".tp-hero-talk-text").removeClass("left");
                                    } else {
                                        $("#player-card").addClass("left");
                                        $(".tp-hero-talk").addClass("left");
                                        $(".tp-hero-talk-text").addClass("left");
                                    }
                                    tamkinPlayerApp.style.setProperty('--tp-player-icon-top', '60%');
                                } else if (mobileLocation === 'postion6') {
                                    if (localStorage.getItem("player_position") === 'right') {
                                        $("#player-card").removeClass("left");
                                        $(".tp-hero-talk").removeClass("left");
                                        $(".tp-hero-talk-text").removeClass("left");
                                    } else {
                                        $("#player-card").addClass("left");
                                        $(".tp-hero-talk").addClass("left");
                                        $(".tp-hero-talk-text").addClass("left");
                                    }
                                    tamkinPlayerApp.style.setProperty('--tp-player-icon-top', '90%');
                                }
                            }
                        }

                        this.update()

                    })
                    .catch(error => {
                    });
            },
            onBeforeUnmount() {
                if (this.observer) {
                    this.observer.disconnect();
                }
            },
            onMounted(props, state) {
                this.getPlayerSettings();

                const soundeffectinplayer = localStorage.getItem("enableSoundEffectInPlayer");
                // const targetNode = this.$('.tp-talk-card');

                // if (targetNode) {
                //     const audio = new Audio(sound);
                //     let previousState = targetNode?.classList?.contains('player-active');
                //     const observer = new MutationObserver((mutationsList) => {
                //         for (const mutation of mutationsList) {
                //             if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                //                 const currentState = mutation.target.classList.contains('player-active');
                //                 if (!previousState && currentState) {
                //                     if (soundeffectinplayer == 1) {
                //                         audio.play();
                //                     }
                //                 }
                //                 previousState = currentState;
                //             }
                //         }
                //     });
                //     observer.observe(targetNode, { attributes: true });
                //     this.observer = observer;

                // }

                let player_settings;
                const componentElement = document.querySelector('tamkin-player-sdk');
                let lang;
                if (componentElement) {
                    lang = componentElement.getAttribute('lang');
                }

                window.tmk_trans_slow = ("Slow");
                window.tmk_trans_normal = ("Normal");
                window.tmk_trans_fast = ("Fast");
                window.tmk_trans_access_link = ("Access Link");




                function loadFile(path) {
                    return 'https://tamkin.app' + path;
                }



            },

            onUpdated() {

                // if (document) {
                //     document.querySelector(".tp-lang-modal-icon").style.display = 'none';
                //     document.querySelector(".tp-accessibility").style.display = 'none';
                // }

                if (this.state.enableColor2) {
                    this.makeGradiant();
                }

                if (this.state.enablePlayerColor2) {
                    document.querySelector(".tp-hero-talk").style.background = "linear-gradient(180deg,var(--tp-player-primary) 0%,var(--tp-player-primary-grad) 100%)"
                    document.querySelector(".tp-hero-talk-text").style.background = "linear-gradient(180deg,var(--tp-player-primary) 0%,var(--tp-player-primary-grad) 100%)"
                }

            },

            hexToRgb(hex) {
                const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
                hex = hex.replace(shorthandRegex, (m, r, g, b) => {
                    return r + r + g + g + b + b;
                });

                const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
                return result
                    ? [
                        parseInt(result[1], 16),
                        parseInt(result[2], 16),
                        parseInt(result[3], 16),
                    ]
                    : null;
            },

            applyFilters() {
                const hex = document.getElementById('tamkinPlayerApp').style.getPropertyValue('--tp-player-primary');
                const rgb = this.hexToRgb(hex);
                let fin;
                if (rgb && rgb.length === 3) {
                    const color = new Color(rgb[0], rgb[1], rgb[2]);
                    const solver = new Solver(color);
                    const result = solver.solve();

                    fin = result.filter;
                }
                return fin;
            },

            makeGradiant() {
                const selectors = [
                    ".iconcodelang",
                    ".tp-accessibility-lang-header",
                    ".tp-accessibility-sidebar-header",
                    ".tp-accessibility2-sidebar-header",
                    ".tp-accessibility3-sidebar-header",
                    ".worldbg",
                    ".tp-accessibility-lang-close:hover",
                    ".pagination button.active",
                    "#tamkinPlayerApp .accessibility-icon-container-widget1 .tp-accessibility",
                    "#tamkinPlayerApp .accessibility-icon-container-widget1 .tp-accessibility-text",
                    ".iconCode",
                    ".iconWorld",
                    "#tamkinPlayerApp .accessibility-icon-container .tp-accessibility2",
                    "#tamkinPlayerApp .accessibility-icon-container .tp-accessibility2-text",
                    "#tamkinPlayerApp .tp-accessibility2-sidebar-close:hover",
                    "#tamkinPlayerApp .accessibility-icon-container .tp-accessibility3",
                    "#tamkinPlayerApp .accessibility-icon-container .tp-accessibility3-text",
                    "#tamkinPlayerApp .tp-accessibility3-sidebar-close:hover",
                    "#tamkinPlayerApp .accessibility-icon-container .tp-accessibility4",
                    "#tamkinPlayerApp .accessibility-icon-container .tp-accessibility4-text",
                    "#tamkinPlayerApp .tp-accessibility4-sidebar-close:hover"
                ];

                selectors.forEach(selector => {
                    const element = document.querySelector(selector);
                    if (element) {
                        element.style.background = "linear-gradient(180deg, var(--tp-primary) 0%, var(--tp-primary-grad) 100%) !important";
                    }
                });
            },
            alert(e) {
                alert(1)
            },

            getPlayerSettings() {
                const lang = localStorage.getItem("player_lang");
                fetch(`https://api.tamkin.app/v1/api/Widget/playerSettings?lang=${lang}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        }
                        return response.json();
                    })
                    .then(data => {
                        this.state.player_settings = data.data;
                        this.update({ player_settings: data.data });
                    })
                    .catch(error => {
                        console.error("Error fetching player settings:", error);
                    });
            },



            makePlayerOn() {
                $(".tp-hero-talk").on("click", window.initiatePlayer);
                $(".tp-hero-talk").hover(
                    function () {
                        $(".tp-hero-talk-text").addClass('active');
                    }, function () {
                        $(".tp-hero-talk-text").removeClass('active');
                    }
                );

                if (localStorage.getItem('tamkinPlayerOpen')) {
                    $(".tp-hero-talk").trigger("click"); // or initiatePlayer()ك
                }
            }
        }
    </script>
    <style>
        /* accessibility */
        /* :host #collapseLang .list-group-item.active::before {
            background-image: url('./images/check.svg');
        } */
    </style>

</main-component>