<accessibility-controls>
    <div class="rounded-4 cards-container">
        <div class="d-flex flex-wrap mx-1">
            <!-- dynamic -->
            <div each="{feature in state.features}"
                class="{props.cols ? props.cols : 'col-6'} accessibility-item {feature.activeState ? 'active' : ''}"
                key="{feature.id}" id="{feature.id}" onclick="{(e) => handleFeature(e, feature)}">
                <div class="inner-item rounded-4 d-flex flex-column align-items-center">
                    <div class="rounded-circle tp-icon lg">
                        <img style="{feature.states[feature.activeState].imgStyle}" class="feature-img"
                            color="var(--tp-primary)"
                            src="{window.tamkin_src_base+feature.states[feature.activeState].image}" alt="">
                    </div>
                    <span
                        class="feature-title font-12 font-w500 mt-1 align-content-center text-center">{feature.states[feature.activeState].title}</span>
                    <div class="feature-levels mt-1">
                        <div>
                            <span each="{(state, index) in feature.states}" key="{index}"
                                if="{index && feature.activeState && feature.states.length > 2}"
                                class="{feature.activeState === index ? 'active' : ''}"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <voice-navigation></voice-navigation>

    <p id="readerDisabled" style="display: none;">Tamkeen screen reader mode is disabled.</p>
    <p id="slowMode" style="display: none;">Tamkeen screen reader slow mode is enabled.</p>
    <p id="speedMode" style="display: none;">Tamkeen screen reader speed mode is enabled.</p>
    <p id="normalMode" style="display: none;">Tamkeen screen reader normal mode is enabled.</p>

    <div class="mt-2 mx-2 resetACC" if="{props.widget_type == 'full_widget' || props.widget_type == 'mini_widget'}">
        <button onclick="{resetAccessibilitySettingsandlocalStorage}"
            class="btn btn-primary tp-bg-primary border-0 btn-sm rounded-4 d-flex align-items-center justify-content-center font-14 font-w500 w-100 h-40px">
            <svg class="h-20px me-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 16">
                <g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-width="1.84">
                    <path
                        d="M16.20106 8c0 .9667-.189683 1.8872-.5324673 2.7251-.3427843.8372-.8386698 1.5911-1.4517524 2.2246-.6130825.6335-1.3426846 1.1459-2.152902 1.5001-.8108948.3542-1.70172746.5502-2.6372711.5502-.93554365 0-1.8263763-.196-2.63727112-.5502-.81021738-.3542-1.53981948-.8666-2.15290203-1.5001M2.6522744 8c0-.9667.189683-1.8872.53246728-2.7251.34278427-.8372.83866982-1.5911 1.45175237-2.2246.61308255-.6335 1.34268465-1.1459 2.15290203-1.5001C7.6002909 1.196 8.49112355 1 9.4266672 1c.93554364 0 1.8263763.196 2.6372711.5502.8102174.3542 1.5398195.8666 2.152902 1.5001">
                    </path>
                    <path stroke-linejoin="round"
                        d="m4.92576062 6.96092-2.48958935 1.484L1 5.87242m13.0125924 2.93832 2.3886509-1.652L18 9.62694">
                    </path>
                </g>
            </svg>
            <span>Reset All Accessibility Settings</span>
        </button>
    </div>

    <script>
        const importAllImages = (requireContext) => requireContext.keys().map(requireContext);
        const images = importAllImages(require.context('/src/player_files/accessibility/img', false, /\.(png|jpe?g|svg)$/));
        window.tamkin_src_base = "";
        if (process.env.NODE_ENV === "production") {
            window.tamkin_src_base = "https://cdn.tamkin.app";
        }
        import fileAudio from "/src/player_files/audio/open_audio.mp3";
        const sound = window.tamkin_src_base + fileAudio;
        import { Color, Solver } from '/src/player_files/accessibility/image_color.js';
        import voiceNavigation from '/src/accessibility/shared/voice-navigation.riot'
        import tinycolor from "tinycolor2";
        export default {
            components: {
                'voice-navigation': voiceNavigation,
            },
            state: {
                features: [],
            },
            handleFeature(e, feature) {
                // increase activeState by 1 until last state after each it becomes 0 =see> (2 % 5 = 2), (5 % 5 = 0)
                feature.activeState = (feature.activeState + 1) % feature.states.length
                let activeStateData = feature.states[feature.activeState]
                switch (feature.id) {
                    case 'acc-addons-main-menu-dyslexia':
                        this.dyslexiaFriendly(e, feature)
                        break;
                    case 'acc-addons-main-menu-pause-animation':
                        this.pauseAnimation(e, feature)
                        break;
                    case 'acc-addons-main-menu-bigger-text':
                        this.biggerText(e, feature)
                        break;
                    case 'acc-addons-main-menu-highlight-links':
                        this.highlightLinks(e, feature)
                        break;
                    case 'acc-addons-main-menu-hide-images':
                        this.hideImages(e, feature)
                        break;
                    case 'acc-addons-main-menu-text-align':
                        this.textAlign(e, feature)
                        break;
                    case 'acc-addons-main-menu-text-spacing':
                        this.textSpacing(e, feature)
                        break;
                    case 'acc-addons-main-menu-line-height':
                        this.lineHeight(e, feature)
                        break;
                    case 'acc-addons-main-menu-page-structure':
                        this.pageStructure(e, feature)
                        break;
                    case 'acc-addons-main-menu-saturation':
                        this.saturation(e, feature)
                        break;
                    case 'acc-addons-main-menu-cursor':
                        this.cursor(e, feature)
                        break;
                    case 'acc-addons-main-menu-contrast':
                        this.contrast(e, feature)
                        break;
                    case 'acc-addons-main-menu-smart-contrast':
                        this.smartContrast(e, feature)
                        break;
                    case 'acc-addons-main-menu-tooltip':
                        this.tooltip(e, feature)
                        break;
                    case 'acc-addons-main-menu-voice-navigation':
                        this.voiceNavigation(e, feature)
                        break;
                    case 'acc-addons-main-menu-screen-reader':
                        this.screenReader(e, feature)
                        break;
                    case 'acc-addons-main-menu-dictionary':
                        this.dictionary(e, feature)
                        break;
                    case 'acc-addons-main-menu-reading-mode':
                        this.readingMode(e, feature)
                        break;
                    case 'acc-addons-main-menu-tamkin-player':
                        this.tamkinPlayer(e, feature)
                        break;
                    case 'acc-addons-main-menu-media-player':
                        this.mediaPlayer(e, feature)
                        break;
                    default:
                        break;
                }
                this.update()
            },
            deactivateMediaPlayerFeature(e, feature) {
                feature.activeState = 0;
                this.update();
            },
            mediaPlayer(e, feature) {
                let modal = window.translateModal[0];
                // deactivate media player acc feature when closing the modal
                if (!modal.listenerBound) {
                    modal.addEventListener('hide.bs.modal', (e) => this.deactivateMediaPlayerFeature(e, feature));
                    modal.listenerBound = true;  // Set a flag indicating the listener is bound
                }

                let translateModalBs = new bootstrap.Modal(modal, {});
                if (feature.activeState) {
                    translateModalBs.show();
                }
            },
            tamkinPlayerOpen() {
                return localStorage.getItem('tamkinPlayerOpen')
            },
            closeAccessibility() {
                document.querySelector('#tamkinPlayerApp #tp-accessibility-sidebar').classList.remove('active');
                const soundeffect = localStorage.getItem("enableSoundEffect");
                const audio = new Audio(sound);
                if (soundeffect == 1) {
                    audio.play();
                }
            },
            tamkinPlayer(e, feature) {
                if (feature.activeState) {
                    this.closeAccessibility()
                    window.initiatePlayer();
                } else {
                    window.closePlayer();
                }
            },
            resetAccessibilitySettingsandlocalStorage() {
                localStorage.removeItem('tpSidebarPosition');
                this.resetAccessibilitySettings();
            },
            resetAccessibilitySettings() {

                if (localStorage.getItem('tpSidebarPosition') == 'left') {
                    $('#tamkinPlayerApp .accessibility-icon-container-lang').addClass('ltr');
                    $('#tamkinPlayerApp .tp-accessibility-lang').addClass('ltr');
                    $('#tamkinPlayerApp #tp-accessibility-sidebar').addClass('ltr');
                    $('#tamkinPlayerApp .accessibility-icon-container-widget1').addClass('ltr');
                    $('#tamkinPlayerApp #tp-accessibility2-sidebar').addClass('ltr');
                    $('#tamkinPlayerApp .accessibility-icon-container').addClass('ltr');
                    $('#tamkinPlayerApp .tp-lang-modal-icon').css('left', 0);
                    $('#tamkinPlayerApp .accessibility-icon-container-widget1.ltr .tp-accessibility').css('left', 0);
                    $('#tamkinPlayerApp .accessibility-icon-container.ltr .tp-accessibility2').css('left', 0);
                    $('#tamkinPlayerApp .tp-lang-modal-icon').css('right', 'auto');
                    $('#tamkinPlayerApp .accessibility-icon-container-widget1 .tp-accessibility').css('right', 'auto');
                    $('#tamkinPlayerApp .accessibility-icon-container .tp-accessibility2').css('right', 'auto');

                } else {
                    $('#tamkinPlayerApp .accessibility-icon-container-lang').removeClass('ltr');
                    $('#tamkinPlayerApp .tp-accessibility-lang').removeClass('ltr');
                    $('#tamkinPlayerApp #tp-accessibility-sidebar').removeClass('ltr');
                    $('#tamkinPlayerApp .accessibility-icon-container-widget1').removeClass('ltr');
                    $('#tamkinPlayerApp .accessibility-icon-container').removeClass('ltr');
                    $('#tamkinPlayerApp #tp-accessibility2-sidebar').removeClass('ltr');
                    $('#tamkinPlayerApp .tp-lang-modal-icon').css('right', 0);
                    $('#tamkinPlayerApp .accessibility-icon-container-widget1 .tp-accessibility').css('right', 0);
                    $('#tamkinPlayerApp .accessibility-icon-container .tp-accessibility2').css('right', 0);
                    $('#tamkinPlayerApp .tp-lang-modal-icon').css('left', 'auto');
                    $('#tamkinPlayerApp .accessibility-icon-container-widget1 .tp-accessibility').css('left', 'auto');
                    $('#tamkinPlayerApp .accessibility-icon-container .tp-accessibility2').css('left', 'auto');
                }

                this.state.features.map(feature => feature.activeState = 0)
                this.update();
                // remove all classes from the dom
                let allAddedClasses = this.state.features.map(feature =>
                    feature.states.map(state => state.addedClass.split(' ')) // Split multiple classes into arrays
                ).reduce((acc, classes) => acc.concat(...classes), []).filter(className => className !== ""); // Flatten the array of arrays
                allAddedClasses = Array.from(new Set(allAddedClasses)); // Remove duplicates
                this.removeClassesFromDom(allAddedClasses);
                // reset cursor guide and mask
                $(document).off('mousemove', this.readingMaskListener);
                $(document).off('mousemove', this.readingGuideListener);
                if (window.$topMask) window.$topMask.remove();
                if (window.$bottomMask) window.$bottomMask.remove();
                if (window.$readingGuide) window.$readingGuide.remove();
                // reset reading mode
                this.resetReadingMode();


            },
            removeClassesFromDom(classes) {
                let addedClassesSelector = classes.map(className => '.' + className).join(', ');
                let addedClassesJoined = classes.join(' ');
                $(addedClassesSelector).removeClass(addedClassesJoined);
            },
            hexToRgb(hex) {
                const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
                hex = hex.replace(shorthandRegex, (m, r, g, b) => {
                    return r + r + g + g + b + b;
                });

                const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
                return result
                    ? [
                        parseInt(result[1], 16),
                        parseInt(result[2], 16),
                        parseInt(result[3], 16),
                    ]
                    : null;
            },
            applyFilters() {
                const hex = document.getElementById('tamkinPlayerApp').style.getPropertyValue('--tp-primary');
                const rgb = this.hexToRgb(hex);
                let fin;
                if (rgb && rgb.length === 3) {
                    const color = new Color(rgb[0], rgb[1], rgb[2]);
                    const solver = new Solver(color);
                    const result = solver.solve();

                    fin = result.filter;
                }
                return fin;
            },
            onBeforeMount(props, state) {
                window.accFeatures = [
                    {
                        id: 'acc-addons-main-menu-tamkin-player', activeState: 0, states: [
                            { title: 'Tamkin Player', image: '/images/tamkin-player-0.svg', addedClass: '', },
                            { title: 'Tamkin Player', imgStyle: this.applyFilters(), image: '/images/tamkin-player-0.svg', addedClass: '', },
                        ]
                    },
                    {
                        id: 'acc-addons-main-menu-media-player', activeState: 0, states: [
                            { title: 'Media Player', image: '/images/media-player-0.svg', addedClass: '', },
                            { title: 'Media Player', imgStyle: this.applyFilters(), image: '/images/media-player-0.svg', addedClass: '', },
                        ]
                    },
                    {
                        id: 'acc-addons-main-menu-screen-reader', activeState: 0, states: [
                            { title: 'Screen Reader', image: '/images/screen-reader-0.svg', addedClass: '', },
                            { title: 'Read Normal', imgStyle: this.applyFilters(), image: '/images/screen-reader-1.svg', addedClass: '', },
                            { title: 'Read Fast', imgStyle: this.applyFilters(), image: '/images/screen-reader-2.svg', addedClass: '', },
                            { title: 'Read Slow', imgStyle: this.applyFilters(), image: '/images/screen-reader-3.svg', addedClass: '', },
                        ]
                    },
                    {
                        id: 'acc-addons-main-menu-voice-navigation', activeState: 0, states: [
                            { title: 'Voice Navigation', image: '/images/voice-navigation-0.svg', addedClass: '', },
                            { title: 'Voice Navigation', imgStyle: this.applyFilters(), image: '/images/voice-navigation-0.svg', addedClass: '', },
                        ]
                    },
                    {
                        id: 'acc-addons-main-menu-dictionary', activeState: 0, states: [
                            { title: 'Dictionary', image: '/images/dictionary-0.svg', addedClass: '', },
                            { title: 'Dictionary', imgStyle: this.applyFilters(), image: '/images/dictionary-0.svg', addedClass: '', },
                        ]
                    },
                    {
                        id: 'acc-addons-main-menu-contrast', activeState: 0, states: [
                            { title: 'Contrast +', image: '/images/contrast-0.svg', addedClass: '', },
                            { title: 'Invert Colors', image: '/images/contrast-1.svg', addedClass: 'tp-contrast-1', },
                            { title: 'Dark Contrast', image: '/images/contrast-2.svg', addedClass: 'tp-contrast-2', },
                            { title: 'Light Contrast', image: '/images/contrast-3.svg', addedClass: 'tp-contrast-3', },
                        ]
                    },
                    {
                        id: 'acc-addons-main-menu-smart-contrast', activeState: 0, states: [
                            { title: 'Smart Contrast', image: '/images/smart-contrast-0.svg', addedClass: '', },
                            { title: 'Smart Contrast', imgStyle: this.applyFilters(), image: '/images/smart-contrast-0.svg', addedClass: '', },
                        ]
                    },
                    {
                        id: 'acc-addons-main-menu-tooltip', activeState: 0, states: [
                            { title: 'Tooltip', image: '/images/tooltip-0.svg', addedClass: '', },
                            { title: 'Tooltip', imgStyle: this.applyFilters(), image: '/images/tooltip-0.svg', addedClass: '', },
                        ]
                    },
                    {
                        id: 'acc-addons-main-menu-page-structure', activeState: 0, states: [
                            { title: 'Page Structure', image: '/images/page-structure-0.svg', addedClass: '', },
                            { title: 'Page Structure', imgStyle: this.applyFilters(), image: '/images/page-structure-0.svg', addedClass: '', },
                        ]
                    },
                    {
                        id: 'acc-addons-main-menu-pause-animation', activeState: 0, states: [
                            { title: 'Pause Animation', image: '/images/pause-animation-0.svg', addedClass: '', },
                            { title: 'Play Animation', imgStyle: this.applyFilters(), image: '/images/pause-animation-1.svg', addedClass: 'tp-pause-animation-1', },
                        ]
                    },
                    {
                        id: 'acc-addons-main-menu-dyslexia', activeState: 0, states: [
                            { title: 'Dyslexia Friendly', image: '/images/df-0.svg', addedClass: '', },
                            { title: 'Dyslexia Friendly', imgStyle: this.applyFilters(), image: '/images/df-1.svg', addedClass: 'tp-dyslexia-friendly-1', },
                            { title: 'Legible Fonts', imgStyle: this.applyFilters(), image: '/images/df-2.svg', addedClass: 'tp-dyslexia-friendly-2', },
                        ]
                    },
                    {
                        id: 'acc-addons-main-menu-cursor', activeState: 0, states: [
                            { title: 'Cursor', image: '/images/cursor-0.svg', addedClass: '', },
                            { title: 'White Big Cursor', imgStyle: this.applyFilters(), image: '/images/cursor-1.svg', addedClass: 'tp-cursor-1', },
                            { title: 'Black Big Cursor', imgStyle: this.applyFilters(), image: '/images/cursor-2.svg', addedClass: 'tp-cursor-2', },
                            { title: 'Reading Mask', imgStyle: this.applyFilters(), image: '/images/cursor-3.svg', addedClass: '', },
                            { title: 'Reading Guide', imgStyle: this.applyFilters(), image: '/images/cursor-4.svg', addedClass: '', },
                        ]
                    },
                    {
                        id: 'acc-addons-main-menu-saturation', activeState: 0, states: [
                            { title: 'Saturation', image: '/images/saturation-0.svg', addedClass: '', },
                            { title: 'Low Saturation', image: '/images/saturation-1.svg', addedClass: 'tp-saturation-1', },
                            { title: 'High Saturation', image: '/images/saturation-2.svg', addedClass: 'tp-saturation-2', },
                            { title: 'Desaturate', image: '/images/saturation-3.svg', addedClass: 'tp-saturation-3', },
                        ]
                    },
                    {
                        id: 'acc-addons-main-menu-text-spacing', activeState: 0, states: [
                            { title: 'Text Spacing', image: '/images/text-spacing-0.svg', addedClass: '', },
                            { title: 'Light Spacing', imgStyle: this.applyFilters(), image: '/images/text-spacing-1.svg', addedClass: 'tp-spacing-1', },
                            { title: 'Moderate Spacing', imgStyle: this.applyFilters(), image: '/images/text-spacing-2.svg', addedClass: 'tp-spacing-2', },
                            { title: 'Heavy Spacing', imgStyle: this.applyFilters(), image: '/images/text-spacing-3.svg', addedClass: 'tp-spacing-3', },
                        ]
                    },
                    {
                        id: 'acc-addons-main-menu-line-height', activeState: 0, states: [
                            { title: 'Line Height', image: '/images/line-height-0.svg', addedClass: '', },
                            { title: 'Line Height(1.5x)', imgStyle: this.applyFilters(), image: '/images/line-height-1.svg', addedClass: 'tp-line-height-1', },
                            { title: 'Line Height(1.75x)', imgStyle: this.applyFilters(), image: '/images/line-height-2.svg', addedClass: 'tp-line-height-2', },
                            { title: 'Line Height(2x)', imgStyle: this.applyFilters(), image: '/images/line-height-3.svg', addedClass: 'tp-line-height-3', },
                        ]
                    },
                    {
                        id: 'acc-addons-main-menu-text-align', activeState: 0, states: [
                            { title: 'Text Align', image: '/images/text-align-0.svg', addedClass: '', },
                            { title: 'Left Align', imgStyle: this.applyFilters(), image: '/images/text-align-1.svg', addedClass: 'tp-class-added tp-text-align-1', },
                            { title: 'Right Align', imgStyle: this.applyFilters(), image: '/images/text-align-2.svg', addedClass: 'tp-class-added tp-text-align-2', },
                            { title: 'Center Align', imgStyle: this.applyFilters(), image: '/images/text-align-3.svg', addedClass: 'tp-class-added tp-text-align-3', },
                            { title: 'Justify Align', imgStyle: this.applyFilters(), image: '/images/text-align-4.svg', addedClass: 'tp-class-added tp-text-align-4', },
                        ]
                    },
                    {
                        id: 'acc-addons-main-menu-bigger-text', activeState: 0, states: [
                            { title: 'Bigger Text', image: '/images/bigger-text-0.svg', addedClass: '', },
                            { title: 'Bigger Text', imgStyle: this.applyFilters(), image: '/images/bigger-text-0.svg', addedClass: 'tp-bigger-text-1', },
                            { title: 'Bigger Text', imgStyle: this.applyFilters(), image: '/images/bigger-text-0.svg', addedClass: 'tp-bigger-text-2', },
                            { title: 'Bigger Text', imgStyle: this.applyFilters(), image: '/images/bigger-text-0.svg', addedClass: 'tp-bigger-text-3', },
                            { title: 'Bigger Text', imgStyle: this.applyFilters(), image: '/images/bigger-text-0.svg', addedClass: 'tp-bigger-text-4', },
                        ]
                    },
                    {
                        id: 'acc-addons-main-menu-highlight-links', activeState: 0, states: [
                            { title: 'Highlight Links', image: '/images/highlight-links-0.svg', addedClass: '', },
                            { title: 'Highlight Links', imgStyle: this.applyFilters(), image: '/images/highlight-links-0.svg', addedClass: 'tp-highlight-links-1', },
                        ]
                    },
                    {
                        id: 'acc-addons-main-menu-hide-images', activeState: 0, states: [
                            { title: 'Hide Images', image: '/images/hide-images-0.svg', addedClass: '', },
                            { title: 'Hide Images', imgStyle: this.applyFilters(), image: '/images/hide-images-0.svg', addedClass: 'tp-hide-images-1', },
                        ]
                    },
                    {
                        id: 'acc-addons-main-menu-reading-mode', activeState: 0, states: [
                            { title: 'Reading Mode', image: '/images/reading-mode-0.svg', addedClass: '', },
                            { title: 'Light Mode', imgStyle: this.applyFilters(), image: '/images/reading-mode-0.svg', addedClass: '', },
                            { title: 'Dark Mode', imgStyle: this.applyFilters(), image: '/images/reading-mode-0.svg', addedClass: 'tp-dark-reading-mode', },
                        ]
                    },
                ];

                if (props.enabledFeatures) {
                    let featuresToEnable = [];
                    for (let i = 0; i < window.accFeatures.length; i++) {
                        let feature = window.accFeatures[i];
                        // get enabled features where name = feature.id
                        let enabledFeature = props.enabledFeatures.find(f => f.name == feature.id);

                        if (enabledFeature) {
                            feature.states[0].title = enabledFeature.label;
                            feature.sort = enabledFeature.sort;
                            featuresToEnable.push(feature);
                        }

                    }
                    if (props.widget_type === 'full_widget') {
                        this.state.features = featuresToEnable?.sort((a, b) => a.sort - b.sort);
                    } else if (props.widget_type == 'mini_widget') {
                        this.state.features = featuresToEnable?.sort((a, b) => a.sort - b.sort).slice(0, 6);
                    } else if (props.widget_type == 'minuscule_widget') {
                        this.state.features = featuresToEnable?.sort((a, b) => a.sort - b.sort).slice(0, 6);
                    }
                }

                // Event bus
                // made to allow accessibility-profile to call the resetAccessibilitySettings function present on accessibility-controls.riot
                window.EventBus = {
                    events: {},
                    dispatch(event, data) {
                        if (!this.events[event]) return; // No subscribers
                        this.events[event].forEach(callback => callback(data));
                    },
                    subscribe(event, callback) {
                        if (!this.events[event]) this.events[event] = []; // New event
                        this.events[event].push(callback);
                    }
                };
                // End Event Bus

                window.globalAffectedElDirect = 'body > *:not(tamkin-player-sdk, tamkin-player-sdk *, #tamkinPlayerApp, #tamkinPlayerApp *, style, script, link)'
                window.globalAffectedEl = 'body *:not(tamkin-player-sdk, tamkin-player-sdk *, #tamkinPlayerApp, #tamkinPlayerApp *, style, script, link)'
            },
            onBeforeUnmount() {
                if (this.observer) {
                    this.observer.disconnect();
                }
            },

            onMounted(props, state) {
                this.update()

                const targetNode = this.$('#voice-nav-modal');
                const observer = new MutationObserver((mutationsList) => {
                    for (const mutation of mutationsList) {
                        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                            if (mutation.target.classList.contains('active')) {
                            } else {
                                const feature = this.state.features.filter((el) => {
                                    return el.id === "acc-addons-main-menu-voice-navigation";
                                })[0];

                                if (feature) {
                                    feature.activeState = 0;
                                }
                                this.update();
                            }
                        }
                    }
                });
                observer.observe(targetNode, { attributes: true });
                this.observer = observer;

                // activate and deactivate tamkinPlayer feature if player is opened or closed without using acc sidebar
                var $this = this
                document.addEventListener('tamkinPlayerStateChanged', function (e) {

                    // let open = e.detail.state;
                    // let tamkinPlayerAccFeature = $this.state.features.find(feature => feature.id === 'acc-addons-main-menu-tamkin-player');

                    // if (open) {
                    //     tamkinPlayerAccFeature.activeState = 1;
                    // } else {
                    //     tamkinPlayerAccFeature.activeState = 0;
                    // }
                    $this.update();
                });
                // event subscription
                window.EventBus.subscribe('resetAccessibility', this.resetAccessibilitySettings.bind(this));
                // page structure

                $(document).ready(function () {
                    window.pageStructureModal = document.getElementById('pageStructureModal');
                    window.pageStructureModalBs = new bootstrap.Modal(window.pageStructureModal, {});
                    // deactivate page structure feature on modal hide
                    window.pageStructureModal.addEventListener('hide.bs.modal', function () {
                        $('#acc-addons-main-menu-page-structure').trigger('click');
                    })




                    // window.DictionaryModal.addEventListener('hide.bs.modal', function () {
                    //     $('#tp-feature-dictionary').trigger('click');
                    // })



                    // Function to handle scrolling to clicked item
                    function scrollToItem(selector) {
                        $('html, body').animate({
                            scrollTop: $(selector).offset().top
                        }, 500);
                    }
                    // Handle clicking on headings
                    $(document).on('click', '#headingsList li', function () {
                        let text = $(this).find('a').data('text');
                        let tagName = $(this).find('a').data('tagname');
                        scrollToItem(tagName + ':contains("' + text + '")'); // Scroll to matching heading
                        window.pageStructureModalBs.hide();
                    });
                    // Handle clicking on landmarks
                    $(document).on('click', '#landmarksList li', function () {
                        let text = $(this).find('a').data('text');
                        let tagName = $(this).find('a').data('tagname');
                        scrollToItem(tagName + ':contains("' + text + '")'); // Scroll to matching landmark
                        window.pageStructureModalBs.hide();
                    });
                });
                // end page structure

                $(function () {
                    $(window.globalAffectedEl).each(function () {
                        let element = this;
                        let $element = $(this);

                        // for line-height and reading mode
                        if (element.childNodes.length === 1 && element.childNodes[0].nodeType === Node.TEXT_NODE) {
                            $(element).addClass('tp-text-only');
                        }
                        // for text-align to add proper styles based on display property
                        let style = window.getComputedStyle(element);
                        let flexDirection = style.getPropertyValue('flex-direction');
                        let display = style.getPropertyValue('display');
                        if (display == 'flex' && (flexDirection != 'column' && flexDirection != 'column-reverse')) {
                            $element.addClass('tp-text-align-plus-justify');
                        }
                    });
                });

                // open accessibility menu ctrl+u
                $(document).on('keydown', function (event) {
                    if (event.ctrlKey && event.key.toLowerCase() === 'u') {
                        event.preventDefault();
                        this.openACC();
                    }
                });

                // TOOLTIP
                function tpTooltipInit($this) {
                    $('body').append('<div class="tp-tooltip-container"></div>');
                    var $tooltip = $('.tp-tooltip-container');
                    // Function to update and position the tooltip
                    function updateTooltip(text, x, y) {
                        $tooltip.text(text).css({
                            'left': x + 20 + 'px', // Offset by 20px from cursor
                            'top': y + 20 + 'px'
                        }).show();
                    }
                    var tooltipFeature = $this.state.features.find(feature => feature.id === 'acc-addons-main-menu-tooltip');
                    if (tooltipFeature) {
                        $('[aria-label], [aria-labelledby], [aria-describedby], [alt], [title]').hover(function (event) {
                            if (!tooltipFeature.activeState) return;
                            // Check if the attribute has a value
                            var attrValue = $(this).attr('aria-label') || $(this).attr('aria-labelledby') || $(this).attr('aria-describedby') || $(this).attr('alt') || $(this).attr('title');
                            if (attrValue) {
                                updateTooltip(attrValue, event.pageX, event.pageY);
                            }
                        }, function () {
                            if (!tooltipFeature.activeState) return;
                            $tooltip.hide(); // Hide tooltip when not hovering
                        });
                    }
                }
                tpTooltipInit(this);
            },
            openACC() {
                document.querySelector("#tamkinPlayerApp #tp-accessibility-sidebar").classList.add("active");
                const soundeffect = localStorage.getItem("enableSoundEffect");
                const audio = new Audio(sound);
                if (soundeffect == 1) {
                    audio.play();
                }
            },
            onUpdated(props, state) {
                let hasActiveFeature = state.features.some(feature => feature.activeState);
                if (hasActiveFeature) {
                    $('.accessibility-icon-container-widget1 .tp-accessibility').addClass('active');
                    $('.accessibility-icon-container .tp-accessibility2').addClass('active');
                    $('.accessibility-icon-container .tp-accessibility3').addClass('active');
                } else {
                    $('.accessibility-icon-container-widget1 .tp-accessibility').removeClass('active');
                    $('.accessibility-icon-container .tp-accessibility2').removeClass('active');
                    $('.accessibility-icon-container .tp-accessibility3').removeClass('active');
                }
            },
            resetAndAddNewClasses($affectedEl, feature) {
                // remove all classes (reset)
                let addedClasses = this.resetOldClasses(feature);
                // add classes based on activeState which refers to the index of the state (represents the suitable class)
                if (feature.activeState) {
                    let addedClass = feature.states[feature.activeState].addedClass;
                    addedClass && $affectedEl.addClass(addedClass);
                }
            },
            // return possible added classes
            resetOldClasses(feature) {
                // let addedClasses = feature.states.filter(state => state.addedClass).map(state => state.addedClass);
                // Extract and flatten all addedClass values, excluding empty strings and split multiple classes
                let addedClasses = feature.states
                    .map(state => state.addedClass) // Extract the addedClass strings
                    .filter(addedClass => addedClass) // Filter out empty strings
                    .flatMap(addedClass => addedClass.split(' ')); // Split multiple classes and flatten

                // Optionally, remove duplicates
                addedClasses = Array.from(new Set(addedClasses));
                this.removeClassesFromDom(addedClasses);

                return addedClasses;
            },

            speak(text, rate, element = '') {
                document.querySelectorAll("*").forEach((el) => {
                    el.classList.remove("highlight")
                })
                window.speechSynthesis.cancel();
                const validRate = isFinite(parseFloat(rate)) ? parseFloat(rate) : 1.0;
                const speech = new SpeechSynthesisUtterance(text);
                speech.lang = `${localStorage.getItem("lang")}-EG`;
                speech.rate = validRate;

                if (element) {
                    element.classList.add("highlight")
                }

                window.speechSynthesis.speak(speech);

                speech.onend = () => {
                    if (element) {
                        element.classList.remove("highlight")
                    }
                };

            },
            handleFocus(event, rate) {
                document.querySelectorAll("*").forEach((el) => {
                    el.classList.remove("highlight")
                })
                const text = event.target.textContent || event.target.value || event.target.alt;
                const element = event.target;
                if (text) {
                    // this.speak(text, rate, event.target);
                    window.speechSynthesis.cancel();

                    const validRate = isFinite(parseFloat(rate)) ? parseFloat(rate) : 1.0;
                    const speech = new SpeechSynthesisUtterance(text);
                    speech.lang = `${localStorage.getItem("lang")}-EG`;
                    speech.rate = validRate;

                    if (element) {
                        console.log("class", element);
                        element.classList.add("highlight")
                    }

                    window.speechSynthesis.speak(speech);
                    speech.onend = () => {
                        if (element) {
                            element.classList.remove("highlight")
                        }
                    };

                }
            },


            screenReader(n, feature) {
                const checkVoiceActive = this.state.features.find(el => el.id === "acc-addons-main-menu-voice-navigation")

                if (checkVoiceActive.activeState == 0) {
                    if (feature.activeState == 0) {
                        document.querySelectorAll('*').forEach(element => {
                            element.removeEventListener('click', this.handleFocus.bind(this));
                        });

                        window.speechSynthesis.cancel();
                        this.speak($("#readerDisabled").text(), 1.0);

                    }
                    else if (feature.activeState == 1) {
                        document.querySelectorAll('*').forEach(element => {
                            element.addEventListener('click', (e) => {
                                if (e.target != n.target && feature.activeState == 1) {
                                    this.handleFocus(e, 1.0);
                                } else {
                                    if (feature.activeState == 1) {

                                        this.speak($("#normalMode").text(), 1.0);
                                    }
                                }
                            });
                        });
                    } else if (feature.activeState == 2) {

                        document.querySelectorAll('*').forEach(element => {
                            element.addEventListener('click', (e) => {
                                if (e.target != n.target && feature.activeState == 2) {
                                    this.handleFocus(e, 2.0);
                                } else {
                                    if (feature.activeState == 2) {
                                        this.speak($("#speedMode").text(), 2.0);
                                    }
                                }
                            });
                        });

                    } else if (feature.activeState == 3) {
                        console.log("false");
                        document.querySelectorAll('*').forEach(element => {
                            element.addEventListener('click', (e) => {
                                if (e.target != n.target && feature.activeState == 3) {
                                    this.handleFocus(e, 0.5);
                                }
                                if (e.target == n.target && feature.activeState == 3) {
                                    this.speak($("#slowMode").text(), 0.5);
                                }
                            });
                        });

                    } else {
                        document.querySelectorAll('*').forEach(element => {
                            element.removeEventListener('click', this.handleFocus.bind(this));
                        });
                        window.speechSynthesis.cancel();
                        this.speak($("#readerDisabled").text(), 1.0);
                    }
                } else {
                    feature.activeState = 0;
                    this.update();
                }

            },

            voiceNavigation(e, feature) {
                const checkScreenReaderActive = this.state.features.find(el => el.id === "acc-addons-main-menu-screen-reader")
                if (checkScreenReaderActive.activeState == 0) {
                    if (feature.activeState == 1) {
                        $("#voice-nav-modal").addClass("active");
                        let hasTriggered = false;
                        if (!hasTriggered) {
                            $("#openlisten").trigger('click');
                            hasTriggered = true;
                        }
                        $('#tamkinPlayerApp #tp-accessibility-sidebar').removeClass('active');
                        // $('#tamkinPlayerApp #tp-accessibility2-sidebar').removeClass('active');
                    } else {
                        $('a:not(#tamkinPlayerApp *)').each(function () {
                            $(this).find('.num_tooltip').remove();
                        });
                        $("#voice-nav-modal").removeClass("active");
                    }
                } else {
                    feature.activeState = 0;
                    this.update();
                }

                this.update()
            },
            closeDictionaryModal() {
                window.DictionaryModalBs.hide();
                window.speechSynthesis.cancel();
                $('#DictionaryModal #dictionarybody').empty();
                this.update();
            },


            dictionary(e, feature) {
                window.DictionaryModal = document.getElementById('DictionaryModal');
                window.DictionaryModalBs = new bootstrap.Modal(window.DictionaryModal, {});


                function getSelectedWords(event) {
                    const range = document.caretRangeFromPoint(event.clientX, event.clientY);
                    if (!range || !range.startContainer || range.startContainer.nodeType !== Node.TEXT_NODE) {
                        return null;
                    }

                    const text = range.startContainer.textContent;
                    const offset = range.startOffset;

                    let start = offset;
                    let end = offset;

                    while (start > 0 && !/\s/.test(text[start - 1])) {
                        start--;
                    }

                    while (end < text.length && !/\s/.test(text[end])) {
                        end++;
                    }

                    const selectedWords = text.substring(start, end).trim();

                    return selectedWords;
                }

                if (feature.activeState) {
                    document.querySelectorAll('*').forEach(element => {
                        element.addEventListener('dblclick', (e) => {
                            const specificElement = document.querySelector('#dictionarybody');
                            const stop1 = document.querySelector(".readBtn");
                            const stop2 = document.querySelector(".spillBtn");
                            const noDatah3 = document.querySelector("#loading h3");
                            const noDatap = document.querySelector("#loading p");
                            const loaderh3 = document.querySelector("#no_data h3");
                            const loaderp = document.querySelector("#no_data p");

                            if (feature.activeState) {
                                if (e.target.closest('#DictionaryModal')) {
                                    return false
                                }
                                if (e.target === specificElement || e.target === stop1 ||
                                    e.target === stop2 || e.target === noDatah3 || e.target === noDatap ||
                                    e.target === loaderh3 || e.target === loaderp) {

                                    return false;
                                } else {
                                    $('#DictionaryModal #dictionarybody').empty();

                                    $('#DictionaryModal #dictionarybody').hide();
                                    $('#DictionaryModal #no_data').hide();
                                    $('#DictionaryModal #loading').show();

                                    const selectedText = getSelectedWords(e);
                                    if (selectedText) {
                                        document.getElementById("dictionaryInput").value = selectedText;

                                        fetch('https://api.tamkin.app/v1/api/Widget/LanguageDictionary', {
                                            method: 'POST',
                                            headers: {
                                                'Content-Type': 'application/json',
                                            },
                                            body: JSON.stringify({
                                                lang: localStorage.getItem("lang"),
                                                text: selectedText
                                            })
                                        })
                                            .then(response => {
                                                if (!response.ok) {
                                                    throw new Error(`HTTP error! Status: ${response.status}`);
                                                }
                                                return response.json();
                                            })
                                            .then(data => {

                                                if (data?.data) {
                                                    $('#DictionaryModal #dictionarybody').show();
                                                    $('#DictionaryModal #loading').hide();
                                                    // $('#DictionaryModal #dictionarybody').append(`<div class="difenition"><span class="wordDif">${selectedText}: </span>${data.data}</div>`);
                                                    $(".readBtn").on('click', () => {
                                                        this.speak(data?.data, 1.0);
                                                    });

                                                    const text = data.data;
                                                    const pageSize = 320;
                                                    let currentPage = 1;

                                                    const pages = Math.ceil(text.length / pageSize);
                                                    const textContainer = document.getElementById('dictionarybody');
                                                    const pageNumbers = document.getElementById('page-numbers');
                                                    const prevButton = document.getElementById('prev');
                                                    const nextButton = document.getElementById('next');

                                                    if (!textContainer || !pageNumbers || !prevButton || !nextButton) {
                                                        return;
                                                    }

                                                    function createPage(pageNumber) {
                                                        const start = (pageNumber - 1) * pageSize;
                                                        const end = Math.min(start + pageSize, text.length);
                                                        return text.slice(start, end);
                                                    }

                                                    function showPage(pageNumber) {
                                                        if (pageNumber < 1 || pageNumber > pages) return;
                                                        textContainer.textContent = createPage(pageNumber);
                                                        currentPage = pageNumber;
                                                        updatePagination();
                                                    }

                                                    function updatePagination() {
                                                        pageNumbers.innerHTML = '';

                                                        const maxVisiblePages = 5;
                                                        const startPage = Math.max(1, currentPage - 2);
                                                        const endPage = Math.min(pages, currentPage + 2);

                                                        if (startPage > 1) {
                                                            addPageButton(1);
                                                            if (startPage > 2) {
                                                                addEllipsis();
                                                            }
                                                        }

                                                        for (let i = startPage; i <= endPage; i++) {
                                                            addPageButton(i);
                                                        }

                                                        if (endPage < pages) {
                                                            if (endPage < pages - 1) {
                                                                addEllipsis();
                                                            }
                                                            addPageButton(pages);
                                                        }

                                                        prevButton.disabled = currentPage === 1;
                                                        nextButton.disabled = currentPage === pages;
                                                    }

                                                    function addPageButton(pageNumber) {
                                                        const button = document.createElement('button');
                                                        button.textContent = pageNumber;
                                                        button.onclick = () => showPage(pageNumber);
                                                        if (pageNumber === currentPage) {
                                                            button.classList.add("active")
                                                        }
                                                        pageNumbers.appendChild(button);
                                                    }

                                                    function addEllipsis() {
                                                        const ellipsis = document.createElement('span');
                                                        ellipsis.textContent = '...';
                                                        pageNumbers.appendChild(ellipsis);
                                                    }

                                                    prevButton.onclick = () => showPage(currentPage - 1);
                                                    nextButton.onclick = () => showPage(currentPage + 1);

                                                    showPage(currentPage);

                                                    this.update()
                                                } else {
                                                    $(".readBtn").off('click', this.speak(data?.data, 1.0));
                                                    $('#DictionaryModal #dictionarybody').hide();
                                                    $('#DictionaryModal #loading').hide();
                                                    $('#DictionaryModal #no_data').show();
                                                }

                                            })

                                            .catch(error => {
                                                console.error('Fetch error:', error);
                                            });

                                        window.speechSynthesis.cancel();
                                        this.closeAccessibility();
                                        window.DictionaryModalBs.show();
                                    }

                                }
                            }
                        })
                    })
                } else {
                    this.closeDictionaryModal()
                }
            },

            smartContrast(e, feature) {
                function suggestNewColor(element, activeState) {
                    const style = getComputedStyle(element);
                    const color = tinycolor(element.style.color || style.color);
                    let bgcolor = tinycolor(style.backgroundColor);
                    const oldcolor = element.dataset.originalColor || style.color;

                    if (!bgcolor.isValid() || bgcolor.getAlpha() === 0) {
                        bgcolor = tinycolor('#ffffff');
                    }

                    const readable = tinycolor.isReadable(color, bgcolor, {
                        level: 'AA',
                    });

                    // Save the original color if not already saved
                    if (!element.dataset.originalColor) {
                        element.dataset.originalColor = oldcolor;
                    }

                    if (activeState === 0) {
                        // Reset to original color, remove inline styles to let CSS handle the color
                        if (element.dataset.originalColor) {
                            element.style.color = '';
                            delete element.dataset.originalColor; // Remove the stored original color
                        }
                    } else if (activeState === 1 && !readable) {
                        // Apply contrast adjustment
                        element.style.color = tinycolor(color).darken(60).toString();
                    }
                }

                // Apply contrast logic to all visible elements
                const elements = document.querySelectorAll('*:not([hidden]):not([type="hidden"])');
                elements.forEach((element) => {
                    if (element.offsetParent !== null) {
                        suggestNewColor(element, feature.activeState);
                    }
                });
            },




            tooltip(e, feature) {
            },
            readingMode(e, feature) {
                let container = $('#reading-mode-container');
                if (!container.length) {
                    $('body').append('<div id="reading-mode-container"><div id="reading-mode-inner"></div></div>');
                    container = $('#reading-mode-container');
                }
                let containerInner = container.find('#reading-mode-inner');
                if (feature.activeState) {
                    if (feature.activeState < 2) {
                        containerInner.empty();
                        containerInner.append(`<h1 style="border-bottom: 1px solid #888;margin-bottom:50px;padding-bottom: 10px;">${document.title}</h1>`);
                        $('.tp-text-only, img').not('a,button,li,i,header *,footer *,nav *, aside *,#tamkinPlayerApp *, #tamkinPlayerApp, tamkin-player-sdk, tamkin-player-sdk *').each(function () {
                            containerInner.append($(this).clone());
                        });
                        $('body > *:not(#reading-mode-container, tamkin-player-sdk, tamkin-player-sdk *, #tamkinPlayerApp, #tamkinPlayerApp *, style, script, link)').addClass('reading-mode-d-none');
                        container.show();
                    } else {
                        // just add dark mode if state is 2
                        container.addClass(feature.states[feature.activeState].addedClass);
                    }
                } else {
                    this.resetReadingMode();
                }
            },
            resetReadingMode() {
                $('.reading-mode-d-none').removeClass('reading-mode-d-none');
                let container = $('#reading-mode-container');
                if (container.length) {
                    container.remove();
                }
            },
            pageStructureHandler() {
                // Clear previous content
                $('#headingsList').empty();
                $('#landmarksList').empty();
                $('#linksList').empty();

                // Get all headings and append to the modal
                $(':not(#tamkinPlayerApp *):header').each(function (index) {
                    let tagName = $(this).prop('tagName');
                    let text = $(this).text();
                    let $headingItem = $(`<li class="heading-link-container">
                    <a href="javascript:void(0)" class="heading-link" data-text="${text}" data-tagname="${tagName.toLowerCase()}">
                        <span class="heading-icon">${tagName}</span> <span class="heading-title">${text}</span>
                    </a>
                    </li>`);
                    $('#headingsList').append($headingItem);
                });

                // Get all landmarks and append to the modal
                $('main, header, footer, nav, section, article, aside, form').not('#tamkinPlayerApp *').each(function (index) {
                    let tagName = $(this).prop('tagName').toLowerCase();
                    let tagNameFormatted = tagName[0].toUpperCase() + tagName.slice(1).toLowerCase();
                    let text = $(this).text();
                    let textFormatted = text.trim().substring(0, 50);
                    textFormatted = textFormatted ? textFormatted[0].toUpperCase() + textFormatted.slice(1).toLowerCase() : textFormatted;
                    let $landmarkItem = $(`<li class="heading-link-container">
                        <a href="javascript:void(0)" class="heading-link" data-text="${text}" data-tagname="${tagName.toLowerCase()}">
                            <img class="link-heading-icon" src="${window.tamkin_src_base}/images/landmark.svg" /> <span class="heading-title">${tagNameFormatted}: ${textFormatted}</span>
                        </a>
                        </li>`);
                    $('#landmarksList').append($landmarkItem);
                });
                // Get all links and append to the modal
                $('a:not(#tamkinPlayerApp *)').each(function (index) {
                    var href = $(this).attr('href');
                    var text = $(this).text();
                    var target = $(this).attr('target');
                    var targetAttr = target ? `target="${target}"` : '';
                    $('#linksList').append(`<li class="heading-link-container">
                        <a href="${href}" class="heading-link" ${targetAttr}>
                            <img class="link-heading-icon" src="${window.tamkin_src_base}/images/link.svg" /> <span class="heading-title">${text}</span>
                        </a>
                    </li>`);
                });

                // Show the modal
                window.pageStructureModalBs.show();
            },
            pageStructure(e, feature) {
                let $el = $('#tamkinPlayerApp #acc-addons-main-menu-page-structure');
                if (feature.activeState) {
                    this.pageStructureHandler();
                }
            },
            pauseAnimation(e, feature) {
                let $affectedEl = $('*')
                this.resetAndAddNewClasses($affectedEl, feature)
                if (feature.activeState) {
                    // Pause all videos and audios
                    $('video, audio').each(function () {
                        this.pause();
                    });
                    // Pause Lottie animations if they exist
                    if (window.lottieAnimations) {
                        window.lottieAnimations.forEach(animation => animation.pause());
                    }
                    // Stop WOW.js animations if WOW is used
                    if (window.wow) {
                        $('.wow').css('visibility', 'hidden'); // Hide all WOW elements
                        window.wow.stop(); // Stop WOW.js from revealing new animations
                    }
                }
            },
            dyslexiaFriendly(e, feature) {
                let $affectedEl = $(window.globalAffectedEl)
                this.resetAndAddNewClasses($affectedEl, feature)
            },
            cursor(e, feature) {
                let $affectedEl = $('html')
                // reset
                $(document).off('mousemove', this.readingMaskListener);
                $(document).off('mousemove', this.readingGuideListener);
                if (window.$topMask) window.$topMask.remove();
                if (window.$bottomMask) window.$bottomMask.remove();
                if (window.$readingGuide) window.$readingGuide.remove();
                this.resetAndAddNewClasses($affectedEl, feature)
                switch (feature.activeState) {
                    case 3:
                        this.readingMask();
                        break;
                    case 4:
                        this.readingGuide();
                        break;
                }
            },
            readingMaskListener(e) {
                let maskHeight = 100;  // Height of the unmasked area
                let topMaskHeight = e.clientY - maskHeight / 2;
                let bottomMaskHeight = window.innerHeight - e.clientY - maskHeight / 2;
                window.$topMask.css({
                    'height': `${Math.max(0, topMaskHeight)}px`,
                    'bottom': `${e.clientY + maskHeight / 2}px`
                });
                window.$bottomMask.css({
                    'height': `${Math.max(0, bottomMaskHeight)}px`,
                    'top': `${e.clientY + maskHeight / 2}px`
                });
            },
            readingMask() {
                window.$topMask = $('.tp-reading-mask#top-mask');
                window.$bottomMask = $('.tp-reading-mask#bottom-mask');
                if (!window.$topMask.length || !window.$bottomMask.length) {
                    window.$topMask = $('<div id="top-mask" class="tp-reading-mask"></div>');
                    $bottomMask = $('<div id="bottom-mask" class="tp-reading-mask"></div>');
                    $('body').append(window.$topMask, window.$bottomMask);
                }
                $(document).mousemove(this.readingMaskListener);
            },
            readingGuideListener(e) {
                var guideWidth = window.$readingGuide.width();
                var windowWidth = $(window).width();
                var guideLeft = Math.max(0, Math.min(windowWidth - guideWidth, e.clientX - guideWidth / 2));

                window.$readingGuide.css({
                    'top': `${e.clientY - 20}px`,
                    'left': `${guideLeft}px`
                });
                window.$readingGuideMarker.css({
                    'top': (e.clientY - 20 + 4) + 'px', // Position above the guide
                    'left': `${e.clientX}px`,
                });

            },
            readingGuide() {
                window.$readingGuide = $('.tp-reading-guide#tp-reading-guide');
                if (!window.$readingGuide.length) {
                    window.$readingGuide = $('<div id="tp-reading-guide" class="tp-reading-guide"><div class="marker"></div></div>');
                    $('body').append(window.$readingGuide);
                }
                window.$readingGuideMarker = $('.tp-reading-guide#tp-reading-guide .marker');
                $(document).mousemove(this.readingGuideListener);
            },
            saturation(e, feature) {
                let $affectedEl = $('html')
                this.resetAndAddNewClasses($affectedEl, feature)
            },
            contrast(e, feature) {
                let $affectedEl = $('body *')
                let $invertColorAffectedEl = $('html')
                let addedClasses = this.resetOldClasses(feature)
                console.log("addedClasses", addedClasses);

                let classIndex = feature.activeState - 1; // because added classes start with 1 (not include 0 (default state)) 
                if (feature.activeState == 1) {
                    // invert colors: applied on body not all elements
                    $invertColorAffectedEl.addClass(addedClasses[classIndex]);
                } else if (feature.activeState > 1) {
                    $affectedEl.addClass(addedClasses[classIndex]);
                }
            },
            textSpacing(e, feature) {
                let $affectedEl = $(window.globalAffectedEl)
                this.resetAndAddNewClasses($affectedEl, feature)
            },
            lineHeight(e, feature) {
                let $affectedEl = $(window.globalAffectedEl)
                this.resetAndAddNewClasses($affectedEl, feature)
            },
            textAlign(e, feature) {
                let $affectedEl = $(window.globalAffectedEl)
                this.resetAndAddNewClasses($affectedEl, feature)
            },
            biggerText(e, feature) {
                let $affectedEl = $(window.globalAffectedElDirect)
                this.resetAndAddNewClasses($affectedEl, feature)
            },
            highlightLinks(e, feature) {
                let $affectedEl = $(window.globalAffectedElDirect)
                this.resetAndAddNewClasses($affectedEl.find('a'), feature)
            },
            hideImages(e, feature) {
                let $affectedEl = $(window.globalAffectedEl)
                this.resetAndAddNewClasses($affectedEl, feature)
            },
            detectLanguage(text) {
                const arabicRegex = /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDCF\uFDF0-\uFDFF\uFE70-\uFEFF]/;
                return arabicRegex.test(text) ? 'ar' : 'en';
            },
            readAloud() {
                const text = document.getElementById('sample-text').textContent;
                const language = this.detectLanguage(text);
                const speech = new SpeechSynthesisUtterance(text);

                // Fetch voices and set the appropriate one based on detected language.
                window.speechSynthesis.onvoiceschanged = function () {
                    const voices = window.speechSynthesis.getVoices();
                    const voice = voices.find(voice => voice.lang.startsWith(language));
                    if (voice) {
                        speech.voice = voice;
                    } else {
                        console.log('No voice available for detected language: ' + language);
                    }
                    window.speechSynthesis.speak(speech);
                };

                // Ensure voices are loaded.
                if (speechSynthesis.getVoices().length === 0) {
                    window.speechSynthesis.speak(new SpeechSynthesisUtterance('')); // trigger voice loading
                } else {
                    window.speechSynthesis.onvoiceschanged();
                }
            },
        }
    </script>

    <style>
        .accessibility-item {
            cursor: pointer;
            margin-top: 8px;
        }

        .accessibility-item.col-4,
        .accessibility-item.col-6 {
            padding: 0px 4px;
        }

        .accessibility-item .inner-item {
            padding: 12px 0;
        }

        .accessibility-item .inner-item .tp-icon {
            background-image: url('https://p.tamkin.app/accessibility/assets/bg-circle.svg');
            background-position: center;
        }

        /* .accessibility-item:nth-last-child(1),
        .accessibility-item:nth-last-child(2) {
            margin-bottom: 0;
        } */

        .accessibility-item>div {
            background-color: white;
            transition: all 0.15s ease;
            border: 2px solid transparent;
            box-shadow: 0 .1875rem .75rem 0 rgba(47, 43, 61, .14);
        }

        .accessibility-item>div:hover {
            border: 2px solid var(--tp-primary);
            /* background-color: var(--tp-primary-light) !important; */
        }

        .accessibility-item.active>div {
            border: 2px solid var(--tp-primary);
            position: relative;
            /* background-color: var(--tp-primary-light) !important; */
        }

        .accessibility-item.active>div:before {
            content: "✓";
            position: absolute;
            top: 0;
            right: 14px;
            background-color: var(--tp-primary);
            /* background-image: url('/images/feature-check.svg'); */
            width: 24px;
            height: 24px;
            text-align: center;
            color: #fff;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
        }

        .accessibility-item .feature-levels {
            position: absolute;
            bottom: 3px;
        }

        .accessibility-item .feature-levels>div {
            display: none;
        }

        .accessibility-item.active .feature-levels>div {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            width: 80px;
        }

        #tp-feature-text-spacing.accessibility-item.active .feature-levels>div,
        #tp-feature-line-height.accessibility-item.active .feature-levels>div,
        #tp-feature-saturation.accessibility-item.active .feature-levels>div,
        #tp-feature-contrast.accessibility-item.active .feature-levels>div {
            width: 60px;
        }

        #tp-feature-dyslexia-friendly.accessibility-item.active .feature-levels>div,
        #tp-feature-reading-mode.accessibility-item.active .feature-levels>div {
            width: 40px;
        }

        .accessibility-item.active .feature-levels>div span {
            background: var(--tp-inactive);
            width: 15%;
            height: 5px;
            border-radius: 10px;
        }

        .accessibility-item.active .feature-levels>div span.active {
            width: 40%;
            background-color: var(--tp-primary);
        }

        #tp-feature-dyslexia-friendly.accessibility-item.active .feature-levels>div span.active,
        #tp-feature-reading-mode.accessibility-item.active .feature-levels>div span.active {
            width: 70%;
        }
    </style>
</accessibility-controls>