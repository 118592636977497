<tamkin-sdk-only>
<!--  
this component is used for Mobile, Dashboard and transcription player
todo  
1- mobile character full screen
2- loader
-->
    <!-- layout -->
    
    <div id="buttons-only-player" > 
        <!-- <lottie-player id="loader" src={createBlob(state.handload)}  background="transparent" speed="1"
            direction="1" mode="normal" loop autoplay>
        </lottie-player> -->
            
        <div class="man-container">
            <div id="{state.dynamicId}"></div>
            <!-- <img src={loadFile('/assets/tamkin_website/img/MAN10.png')} class="img-man"> -->
        </div>
    </div>
      
     <script >
    // jQuery is now available in all project see webpack.config.js:126
    // import $ from 'jquery';
     import * as THREE from 'three';
     let play;
     let action;
     let Init_action;
     let speedCaracter = 1;
     let controls;

      window.state_animation = "idle"; 
      window.tmk_trans_background_100 = ("Background 100%");
      window.tmk_trans_background_50 = ("Background 50%");
      window.tmk_trans_transparent = ("Transparent");

      import rigNewOutfit from './files3D/newC/rig_new_outfit.fbx';
      //import fares_rigging from "./player_files/files3D/fares_rigg_optmized_001.fbx";
      import fares_rigging from "./player_files/files3D/anim_clothes_off 01.fbx";
      import qassem_rigging from "./player_files/files3D/qassem.fbx";
      import pose_animation from "./player_files/files3D/tanslation_pose_anim_only.fbx";
      const componentElementVers = document.querySelector('tamkin-player-sdk');
      let version;
      if (componentElementVers)
      {
      version = componentElementVers.getAttribute('with-player');
      }
     //import fares_rigging from "./player_files/files3D/tanslation_pose_body_glb.glb";
  
     
     
              import Stats from 'three/addons/libs/stats.module.js';
  
              import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
              import { FBXLoader } from 'three/addons/loaders/FBXLoader.js';
  
              import {GLTFLoader} from 'three/addons/loaders/GLTFLoader.js';
              //import {Keyboard} from 'virtual-keyboard';
            //   import {Keyboard} from './libs/jquery.keyboard.js';
            //   import 'jquery-ui/ui/widgets/draggable';
            //   import 'jquery-ui/ui/position';
              //import './libs/jquery.ui.touch-punch.min.js';
            //   import './libs/jquery.ui.touch-punch.js';
              // import "@lottiefiles/lottie-player";
              import handload from './player_files/json/handload.json';
    import hearts from './player_files/json/hearts.json';
    import loading from './player_files/json/loading.json';
    import twohand from './player_files/json/twohand.json'; 
              const componentElement = document.querySelector('tamkin-sdk');
              let lang;
              if (componentElement)
              {
              lang = componentElement.getAttribute('lang');
              }
              let charWidth = 200;
              let charHeight = 218;
              if (componentElement)
              {
                charWidth = componentElement.getAttribute('charWidth');
                charHeight = componentElement.getAttribute('charHeight');

              }
             
              
              
      export default { 
        
      state : {
      lang:lang,
      with_player:version,
      with_player:version,
      handload:handload,
      hearts:hearts,
      loading:loading,
      twohand:twohand,
      charWidth:parseInt(charWidth),
      charHeight:parseInt(charHeight)
       },
       onBeforeMount() {
    // Generate a unique ID for each component instance before it mounts
            this.state.dynamicId = `element-${Date.now()}--${Math.random().toString(36).substr(2, 9)}`;
        },
        createBlob(json){

const blob = new Blob([JSON.stringify(json)], { type: 'application/json' });

// Create a URL object from the Blob
const url = URL.createObjectURL(blob);
return url;
} ,
  
        onMounted() {
          play=true;
         var $this = this;
         let dynamicId = this.state.dynamicId;
         const textureLoader = new THREE.TextureLoader();
         let stream ; // Capture at 60 fps if possible
let recordedChunks = [];
  var scene, camera, renderer,character;			
    var i=0;
    var clock, mixer, actions = {}, activeAction;
    let Character ;

  



    function adjustCameraBasedOnCharacter(position) {
      if(position == 1)
   {
  
    
  // Adjust camera position. The Y position is set to half the character's height
  // to aim at the middle of the character. Adjust Z to move the camera back
  // a distance calculated above. X is set to 0 assuming the character is centered.
  var width = $this.state.charWidth === 0 ? window.innerWidth : $this.state.charWidth;
  var height= $this.state.charHeight === 0 ?  window.innerHeight: $this.state.charHeight;
  camera = new THREE.PerspectiveCamera(50, (width / height ),1, 10);
  
  camera.position.set(0, 1, 2);
  
  
  // Update the camera's projection matrix to apply changes
  camera.zoom = 1.5; 

  camera.updateProjectionMatrix();
                  
  // If using OrbitControls or similar, you might want to update the target
  if (controls) {
    controls.target.set( 0, 1, 0 );
        controls.update();
  }

  
  }

   
   
      else if(position == 2)
   {
   
  var bbox = new THREE.Box3().setFromObject(window.character);

  var size = new THREE.Vector3();
  bbox.getSize(size);
 
  // Calculate a suitable distance of the camera from the character
  // This is a bit arbitrary and depends on your scene scale and FOV
  var distance = size.y * 1.8;

  // Adjust camera position. The Y position is set to half the character's height
  // to aim at the middle of the character. Adjust Z to move the camera back
  // a distance calculated above. X is set to 0 assuming the character is centered.
  camera.position.set(0, size.y / 3.2, distance);

  // Adjust the camera's near and far planes to avoid clipping
  camera.near = distance * 0.1;
  camera.far = distance * 10;

  // Update the camera's projection matrix to apply changes
  camera.updateProjectionMatrix();

  // If using OrbitControls or similar, you might want to update the target
  if (controls) {
    controls.target.set(0, size.y / 3.8, 0);
    controls.update();
  }
  }
  

}
  
 function initiatePlayer() {
  setTimeout(() => {
    if(window.characterLoadStarted)
  {   
    window.characterLoadStarted();
  }
  if(window.flutter_inappwebview)
  {
  window.flutter_inappwebview.callHandler('characterLoadStarted');
  }
	}, 10);
 
          
    init(dynamicId, function(loadedCharacter) {
        
        animate();
        window.character= loadedCharacter;
           
          const allClothesNames = getClothesNames(loadedCharacter);
          adjustCameraBasedOnCharacter(1);
          var width = $this.state.charWidth ;
          if(width == 0)
          {
            adjustCameraBasedOnCharacter(2);
          }

      });
      

      
      
        //   const allClothesNames = getClothesNames(loadedCharacter);
        //   console.log(allClothesNames);

        // loadCharacter(qassem_rigging, function(loadedCharacter) {
        //                callback(loadedCharacter);
        //           });
   
  }
 function changeSpeed(speed) {
      
         window.action.timeScale = speed ;
     
 }
  
  function init(idContainner,callback) {
  var element = document.getElementById("player-card");
  var container= document.getElementById(idContainner);

  var width = $this.state.charWidth === 0 ? window.innerWidth : $this.state.charWidth;
  var height= $this.state.charHeight === 0 ?  window.innerHeight: $this.state.charHeight;

  // if (element) {
  //     var rect = element.getBoundingClientRect();
  //     width = rect.width;
  //     height = rect.height;
  
  // } 
  
              scene = new THREE.Scene();
              renderer = new THREE.WebGLRenderer({ antialias: true,alpha: true  });
              renderer.setSize(width, height);
              renderer.shadowMap.enabled = true;
              
             //$this.root.appendChild(renderer.domElement);
             container.appendChild(renderer.domElement);

        
              //renderer.setClearColor(0x000022, 0);
                  clock = new THREE.Clock();
                  //scene.fog = new THREE.Fog( 0xa0a0a0, 10, 50 );
  
                  const hemiLight = new THREE.HemisphereLight( 0xffffff, 0x8d8d8d, 3 );
                  hemiLight.position.set( 0, 20, 0 );
                  scene.add( hemiLight );
                  const dirLight = new THREE.DirectionalLight( 0xffffff, 1 );
                  dirLight.position.set( 3, 10, 10 );
                  dirLight.castShadow = true;
                  dirLight.shadow.camera.top = 2;
                  dirLight.shadow.camera.bottom = - 2;
                  dirLight.shadow.camera.left = - 2;
                  dirLight.shadow.camera.right = 2;
                  dirLight.shadow.camera.near = 0.1;
                  dirLight.shadow.camera.far = 40;
                  scene.add( dirLight );
                  let c ;
                  loadCharacter(fares_rigging, function(loadedCharacter) {
                       callback(loadedCharacter);
                  });
                  
                
              var light = new THREE.AmbientLight(0xffffff);
              scene.add(light);
                  
  
                      camera = new THREE.PerspectiveCamera(50, (width / height ),1, 10);
                      camera.position.set(0, 1, 2);
                  camera.zoom = 1.5; 
                  camera.updateProjectionMatrix();
                  controls = new OrbitControls( camera, renderer.domElement );
                  controls.enablePan = false;
                  controls.enableZoom = false;
                  controls.target.set( 0, 1, 0 );
                  controls.enabled = false;
                  controls.update();
  
                  
              // Load the character model first
              return (c);
              
          }
       
          function showClothesVisibility(name) {
            window.character.traverse((child) => {
        if (child.name.toLowerCase() === name.toLowerCase()) {
     
            child.visible = true; 
        }
    });
}
function hideClothesVisibility(name) {
    window.character.traverse((child) => {
        if (child.name.toLowerCase() === name.toLowerCase()) {
     
            child.visible = false; 
        }
    });
}
          function getClothesNames(character) {
    let clothesNames = [];
    
    character.traverse(function (child) {
        // Here, you might want to include some logic to determine whether the child is considered 'clothes'
        // This could be based on naming conventions, specific object properties, or layers
        if (child.isMesh) {
            clothesNames.push(child.name);
    }   
    });

   
    return clothesNames;
}
  
   const animationJSONPath = 'https://api.tamkin.app/v1/api/Ai/GetAnimation';


  function getAdAnimate(text,speed=1)
  {
    
      let arr;
      const load = new GLTFLoader();
              var loader = new FBXLoader();
              
              
      fetch(animationJSONPath,{
  method: 'POST',
    headers: {
      'Content-Type': 'application/json' ,
      'Accept' :'*/*'
    },
    body:JSON.stringify({
      "text":text,
      "lang" : lang
  })
  }
  
  )
      .then(response => response.json())
      .then(animationData => {
         
          arr = animationData.data;
          arr.map((animation) => {
  load.load('https://api.tamkin.app/'+animation, (gltf) => {
  
      // Animation setup
      
      //if (gltf.animations && gltf.animations.length) {

          gltf.animations.forEach((clip) => {

              if (action && action.isRunning())
             {
              window.state_animation = "Running"

              if (window.onRunning)
              {
                window.onRunning();
              }
              if (window.flutter_inappwebview)
              {
                window.flutter_inappwebview.callHandler('onRunning');
              }

                action.stop();

                mixer.uncacheRoot(clip);
                    
             }
               
            mixer.stopAllAction();
            window.mixer = mixer;
            action = mixer.clipAction(clip);
              //newAction.reset().fadeIn(duration).play();
              action.timeScale = speed;
              action.loop = THREE.LoopOnce; // Ensure the animation only plays once
              action.time = 0;
              action.stop();
            
       mixer.addEventListener('finished', function onFinished(e) {
            if (e.action === action) {
                // Remove the event listener to prevent it from being called multiple times
                //mixer.stopAllAction();

                mixer.removeEventListener('finished', onFinished);
                

              
                // When the animation finishes, possibly resume the principal animation
                crossfade(action, Init_action, 0)

                //Init_action.reset().play();
            window.state_animation = "Finished"
            if (window.onFinished)
              {
                window.onFinished();
              }
              if (window.flutter_inappwebview)
              {
                window.flutter_inappwebview.callHandler('onFinished');              }
              
              }
            
            });
        setTimeout(() => {
          if (window.flutter_inappwebview)
              {
          window.flutter_inappwebview.callHandler('onAnimFinished');              
              }

          }, 500);
        action.reset().play().clampWhenFinished = true;
        window.action = action;
        
          });
      //}
  }, undefined, (error) => {
      console.error('An error happened while loading the model:', error);
  });
  });
          // Process the loaded animation data
          
      }) 
  
  
  
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
 function replay()
 {
  console.log(window.action);
   window.action.reset().play();
 }
  
 
      
  function loadCharacter(url,callback) {
              var loader = new FBXLoader();
              var lad = new GLTFLoader();
              var shirtT ;
              var obj;
              const workerScript = `
  self.addEventListener('message', e => {
    const urlsToFetch = e.data; // Array of URLs or criteria to determine URLs
    // Example: Directly send back the received URLs or modify as needed
    self.postMessage(urlsToFetch);
    // Add any fetching or processing logic here
  });
  `;
  const blob = new Blob([workerScript], { type: 'application/javascript' });
  const workerUrl = URL.createObjectURL(blob);
  
  const worker = new Worker(workerUrl);
             
  worker.onmessage = function(e) {
  
  const objectURL = e.data; // URLs fetched or determined by the worker
  
  
  loader.load(objectURL, function (object1) {
                  //let object1 =  obj.scene;
                 
                  
                  let obj =object1
                  object1.scale.set(0.0119, 0.0119, 0.0119);
                  object1.position.y -= 0.41
                          
           
                    
      
                  mixer = new THREE.AnimationMixer(object1);
              
                  scene.add(object1);
                  obj.traverse(function (node) {
                    if(node.name.includes("clothes"))
                    {
        if (node.name !== "clothes_glasses1" &&  node.name !==  "clothes_tie1_blueblack" &&
            node.name !== "clothes_orignal_pants_blueblack" &&
            node.name !== "clothes_orignal_shirt_aquamarine" &&
            node.name !== "clothes_orignal_shoes_Havana"
            // node.name !== "clothes_shirt_purple"
            ) {
           node.visible = false;
                  }
                  
                }
                   });

            
  
  hideLoaders();
  firstanimation();
  document.getElementById('buttons-only-player').style.display = 'block';
  URL.revokeObjectURL(workerUrl);
  
  if (callback) {
    callback(obj); // Invoke the callback with the loaded object
  }     
  
  
           
            
            });
             
                  }
                  
                 worker.postMessage(url);
                 
              }
  
              
                     
  
              
              
function crossfade(actionFrom, actionTo, duration) {
      // Start fading out the current action
      //actionFrom.fadeOut(duration);
      actionFrom.crossFadeTo( actionTo, duration, true );
      // Ensure the action to fade in starts from the beginning
      actionTo.reset();
  
      // Start fading in the new action
      actionTo.fadeIn(duration);
  
      // Start playing the new action
      actionTo.play();
      console.log(renderer.domElement);
  }
  
          function setActiveAction(name) {
              var action = actions[name];
  
               action.play();
  
  
               activeAction = action;
          }
          
          function firstanimation()
 {
    const load = new FBXLoader();

    load.load(pose_animation, (gltf) => {

  // Animation setup
  
  //if (gltf.animations && gltf.animations.length) {
        /*  if (Init_action && Init_action.isRunning())
        {
             
            action.stop();

            mixer.uncacheRoot(clip);
                
        }*/

        Init_action =  mixer.clipAction(gltf.animations[0]).play();
         
      });
 }
 function hideLoaders(){
      if(window.characterLoadFinished)
      {
        window.characterLoadFinished();
      }
      if(window.flutter_inappwebview)
      {
        window.flutter_inappwebview.callHandler('characterLoadFinished');
      }


      //$("#loader").delay(1000).fadeOut();
      //$("#loader-hand").delay(2000).fadeOut();
      //$('body').delay(3000).addClass('player-actived');
  }
 
          function animate() {
              requestAnimationFrame(animate);
  
              var delta = clock.getDelta();
              if (mixer) mixer.update(delta);
  
              renderer.render(scene, camera);
          }
       
          initiatePlayer();

          window.showClothesVisibility = showClothesVisibility;
          window.hideClothesVisibility = hideClothesVisibility;
          window.changeSpeed = changeSpeed;
          window.getAdAnimate = getAdAnimate;
          window.adjustCameraBasedOnCharacter = adjustCameraBasedOnCharacter;
          window.replay = replay;
        },
        
        
        onUpdated() { 
        },
        alert(e) {
          alert(1)
        }
      }
    </script>
    <style>
      /* :host { display: block } */
      /* h3 { font-size: 120% }  */
    </style>
  
  </tamkin-sdk-only>