<language-widget>

    <div if="{!sidebarIsHidden() && props.sidebarPosition !== 'hide'}">
        <div>
            <div class="accessibility-icon-container-lang notranslate">

                <div if="{props.langModeIcon}" class="tp-lang-modal-icon" style="{getTopVar(props.widgetType)}">
                    <div class="iconCode" onclick="{showSideLang}" if="{props.langModeIcon == 'option2'}">
                        {activeLang()?.language_code}
                    </div>
                    <div onclick="{showSideLang}">
                        <img class="iconFlag" width="48" height="48" if="{props.langModeIcon == 'option1'}"
                            src="{loadFile(activeLang()?.image)}" alt="">
                    </div>
                    <div onclick="{showSideLang}" class="iconWorld" if="{props.langModeIcon == 'option3'}">
                        <img width="36" height="36" src="{window.tamkin_src_base+'/images/ground.svg'}" alt="">
                    </div>
                </div>
            </div>

            <div class="tp-accessibility-lang" id="tp-accessibility-lang">
                <div class="tp-accessibility-lang-header text-white border-bottom
                    d-flex align-items-center justify-content-between">
                    <p class="font-16 font-w500 mb-0">
                        Live Translator
                    </p>
                    <a onclick="{closeSideLang}" class="tp-accessibility-lang-close rounded-circle"
                        href="javascript:void(0)">
                        <div></div>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 6L6 18" stroke="white" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" />
                            <path d="M6 6L18 18" stroke="white" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" />
                        </svg>
                    </a>

                </div>

                <!-- body -->
                <div class="tp-lang-sidebar-body" if="{state.languages.length}">
                    <div class="mx-3 mt-3" id="languages-box">
                        <div class="form-group mb-3 px-1" id="tp-search-gp">
                            <label for="tp-search"><img class="h-25px"
                                    src="{window.tamkin_src_base+'/images/icon-search.svg'}" alt="search"></label>
                            <input oninput="{searchLanguages}" type="text" id="tp-search"
                                class="form-control rounded-4 ps-5 h-50px" placeholder="Search by Keyword...">
                            <span if="{ document.getElementById('tp-search').value.length> 0}"
                                onclick="{(e) => removeSearchLanguageTrim(e)}" id="tp-search-remove">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18 6L6 18" stroke="#585B5B" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <path d="M6 6L18 18" stroke="#585B5B" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                </svg>
                            </span>
                        </div>
                        <hr class="mb-2">
                        <div if="{ state.filteredLanguages.length}" class="list-group list-group-flush notranslate">
                            <a each="{(lang, index) in state.filteredLanguages}" id="g-lang-{lang.language_code}"
                                key="{lang.language_code}" href="javascript:void(0)"
                                onclick="{(e) => changeLanguage(e, lang)}"
                                class="{(activeLang())?.language_code && lang.language_code==(activeLang()).language_code?'active':''} px-4 
                                list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                                <div class="d-flex g-lang-inside">
                                    <div style="text-transform: capitalize;" class="rounded-circle tp-icon text-center"
                                        if="{props.langModeIcon == 'option2'}">
                                        <span>{lang.language_code}</span>
                                    </div>
                                    <div class="rounded-circle text-center" if="{props.langModeIcon == 'option1'}">
                                        <img width="30" height="26" src="{loadFile(lang.image)}" alt="">
                                    </div>
                                    <div if="{props.langModeIcon == 'option3'}"
                                        class="rounded-circle tp-icon text-center worldbg d-flex align-items-center justify-content-center">
                                        <img width="26" height="26" src="{window.tamkin_src_base+'/images/ground.svg'}"
                                            alt="">
                                    </div>
                                    <span
                                        class="px-2 font-16 font-w400 mb-0 align-content-center">{lang.language_name}</span>
                                </div>
                            </a>
                        </div>


                        <div class="noDataFound" id="noDataFound">
                            <svg xmlns="http://www.w3.org/2000/svg" width="31" height="33" viewBox="0 0 31 33"
                                fill="none">
                                <g clip-path="url(#clip0_5_16)">
                                    <path
                                        d="M0.29352 30.7591L8.29738 22.6292C6.20142 20.2786 4.9136 17.1699 4.9136 13.7491C4.91461 6.43136 10.7538 0.5 17.9573 0.5C25.1608 0.5 31 6.43136 31 13.7491C31 21.0668 25.1608 26.9982 17.9573 26.9982C14.8449 26.9982 11.9902 25.887 9.74795 24.0395L1.71304 32.2014C1.32154 32.5995 0.685934 32.5995 0.294432 32.2014C0.201311 32.1075 0.127296 31.9954 0.0767347 31.8716C0.0261747 31.7478 8.60338e-05 31.6148 2.03136e-07 31.4804C-8.37202e-05 31.3461 0.0258371 31.2131 0.0762407 31.0892C0.126644 30.9653 0.200516 30.8531 0.29352 30.7591ZM17.9573 24.9598C24.0523 24.9598 28.9932 19.9406 28.9932 13.7491C28.9932 7.55762 24.0523 2.53845 17.9573 2.53845C11.8623 2.53845 6.92142 7.55762 6.92142 13.7491C6.92142 19.9406 11.8623 24.9598 17.9573 24.9598Z"
                                        fill="#5E6175" />
                                    <path
                                        d="M26.5 14C26.5 9.30558 22.6944 5.5 18 5.5C13.3056 5.5 9.5 9.30558 9.5 14C9.5 18.6944 13.3056 22.5 18 22.5C22.6944 22.5 26.5 18.6944 26.5 14Z"
                                        fill="white" />
                                    <path
                                        d="M26.5 14C26.5 9.30558 22.6944 5.5 18 5.5C13.3056 5.5 9.5 9.30558 9.5 14C9.5 18.6944 13.3056 22.5 18 22.5C22.6944 22.5 26.5 18.6944 26.5 14Z"
                                        stroke="#5E6175" />
                                    <path
                                        d="M22.2405 10.0038C22.0811 9.8444 21.8649 9.75488 21.6396 9.75488C21.4142 9.75488 21.198 9.8444 21.0386 10.0038L18.2396 12.8028L15.4405 10.0038C15.2802 9.84892 15.0655 9.76324 14.8426 9.76518C14.6197 9.76711 14.4066 9.85651 14.249 10.0141C14.0914 10.1717 14.002 10.3849 14 10.6078C13.9981 10.8306 14.0838 11.0453 14.2386 11.2057L17.0377 14.0047L14.2386 16.8038C14.0838 16.9641 13.9981 17.1788 14 17.4016C14.002 17.6245 14.0914 17.8377 14.249 17.9953C14.4066 18.1529 14.6197 18.2423 14.8426 18.2442C15.0655 18.2462 15.2802 18.1605 15.4405 18.0057L18.2396 15.2066L21.0386 18.0057C21.1989 18.1605 21.4136 18.2462 21.6365 18.2442C21.8594 18.2423 22.0726 18.1529 22.2302 17.9953C22.3877 17.8377 22.4771 17.6245 22.4791 17.4016C22.481 17.1788 22.3953 16.9641 22.2405 16.8038L19.4415 14.0047L22.2405 11.2057C22.3999 11.0463 22.4894 10.8301 22.4894 10.6047C22.4894 10.3793 22.3999 10.1631 22.2405 10.0038Z"
                                        fill="var(--tp-primary)" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_5_16">
                                        <rect width="31" height="33" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <h3>No Results Found </h3>
                            <p>Please check your spelling and try again.</p>
                        </div>

                    </div>
                </div>

                <div class="tp-accessibility-sidebar-footer"
                    style="background-image: url('/images/acc-side-footer-bg.svg')">
                    <div class="d-flex align-items-center justify-content-between py-2 px-3">
                        <a class="d-block" href="{props.accessibility_settings?.accessibility_settings?.tamkin_url}"
                            target="_blank">
                            <img src="{window.tamkin_src_base+'/images/acc-side-footer-logo.svg'}" alt="Tamkin">
                        </a>
                        <div class="text-center">
                            <p class="mb-1 font-14 font-w400">Report a problem</p>
                            <a style="padding: 1px 10px !important;"
                                href="mailto:{props.accessibility_settings?.accessibility_settings?.support_email}"
                                class="btn btn-primary tp-bg-primary border-0 px-0 btn-sm ">
                                Report
                            </a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <script>
            window.tamkin_src_base = "";
            if (process.env.NODE_ENV === "production") {
                window.tamkin_src_base = "https://cdn.tamkin.app";
            }
            import "/src/player_files/accessibility/bootstrap.min.js";
            import fileAudio from "/src/player_files/audio/open_audio.mp3";
            const sound = window.tamkin_src_base + fileAudio;
            const audio = new Audio(sound);

            export default {
                components: {
                },
                state: {
                    defaultLang: { 'language_name': 'English (USA)', 'language_code': 'en', 'language_code_display': 'EN', 'image': `/files/us0d2edf.svg` },
                    languages: [],
                    filteredLanguages: [],
                },

                showSideLang() {
                    document.getElementById("tp-accessibility-lang").classList.add("active")
                    const soundeffect = localStorage.getItem("enableSoundEffect");
                    if (soundeffect == 1) {
                        audio.play();
                    }
                },
                closeSideLang() {
                    document.getElementById("tp-accessibility-lang").classList.remove("active")
                    const soundeffect = localStorage.getItem("enableSoundEffect");
                    const audio = new Audio(sound);
                    if (soundeffect == 1) {
                        audio.play();
                    }
                },

                getTopVar(type) {
                    if (type === 'full-widget') {
                        return `top: calc(var(--tp-accessibility-top) - var(--tp-accessibility-icon-height) - 24px);`;
                    } else if (type === 'mini-widget') {
                        return `top: calc(var(--tp-accessibility2-top) - var(--tp-accessibility2-icon-height) - 24px);`;
                    } else if (type === 'minuscule-widget') {
                        return `top: calc(var(--tp-accessibility3-top) - var(--tp-accessibility3-icon-height) - 24px);`;
                    } else {
                        return `top: calc(var(--tp-accessibility4-top) - var(--tp-accessibility4-icon-height) - 24px);`;
                    }
                },

                activeLang() {
                    let activeLang = this.state.filteredLanguages.find(lang => lang.active);
                    return activeLang ?? this.state.defaultLang
                },
                onBeforeMount(props, state) {
                    // console.log(" props.mainlanguages", props.mainlanguages);


                    // console.log("props", props.languages);

                    // fetch('https://tamkin.app/api/method/tamkin_website.api.getAccessibilityData')
                    //     .then(response => {
                    //         if (!response.ok)
                    //             throw new Error(`HTTP error! Status: ${response.status}`);
                    //         return response.json();
                    //     })
                    //     .then(data => {
                    // this.update({
                    //     languages: props.mainlanguages,
                    //     filteredLanguages: props.mainlanguages,
                    // });
                    // })
                    // .catch(error => { });

                },
                onMounted(props, state) {
                    this.state.languages = this.props.mainlanguages
                    this.state.filteredLanguages = this.props.mainlanguages
                    this.update();

                    $('body > *:not(#tamkinPlayerApp,tamkin-player-sdk, #tamkinPlayerApp *,tamkin-player-sdk *)').on('click', () => {
                        if ($("#tp-accessibility-lang").hasClass("active")) {

                            $("#tp-accessibility-lang").removeClass("active")
                            const soundeffect = localStorage.getItem("enableSoundEffect");
                            const audio = new Audio(sound);
                            if (soundeffect == 1) {
                                audio.play();
                            }
                        }
                    });

                    // google trans
                    var selectBoxContainer = document.querySelector("#google_translate_element");
                    if (selectBoxContainer) {
                        var $this = this;
                        selectBoxContainer.addEventListener('elementLoaded', () => {
                            var selectBox = document.querySelector("#google_translate_element select.goog-te-combo");
                            let secondsPassed = 0;
                            let langInterval = setInterval(() => {
                                if (selectBox.value) {
                                    $this.activateLanguage(selectBox.value);
                                    clearInterval(langInterval);
                                }
                                if (secondsPassed > 5) {
                                    clearInterval(langInterval);
                                }
                                secondsPassed++;
                            }, 1000);
                        })
                    }
                },
                activateLanguage(active_lang) {
                    // check lang_name is string or object
                    let activeLanguage;
                    if (typeof active_lang == 'string') {
                        activeLanguage = this.state.languages.find(l => l.language_code == active_lang);
                    } else {
                        activeLanguage = active_lang
                    }

                    // set lang as active and deactivate others
                    this.state.filteredLanguages.forEach(function (l) { if (l.active) { l.active = false } });
                    activeLanguage.active = true;
                    this.update()

                    // clone the active language html to inside #g-lang-active (accordion btn)
                    let activeLangHTML = $("#g-lang-" + activeLanguage.language_code + " .g-lang-inside").html()
                    $("#g-lang-active .g-lang-inside").html(activeLangHTML);

                    // close the language accordion
                    if ($("#languages-box").hasClass('show')) {
                        $("#languages-box").removeClass('show')
                        $("#g-lang-active").attr('aria-expanded', 'false').removeClass('collapsed');
                    }

                    // rtl sidebar
                    setTimeout(() => {
                        if ($('html').hasClass('translated-rtl')) {
                            $(' #tp-accessibility-sidebar').addClass('rtl');
                        } else {
                            $(' #tp-accessibility-sidebar').removeClass('rtl');
                        }
                    }, 500);


                    localStorage.setItem("lang", activeLanguage.language_code)
                },
                onUpdated(props, state) {

                },
                searchLanguages(e) {
                    let search = $(e.target).val();
                    this.update({ filteredLanguages: this.state.languages.filter(lang => lang.language_name.toLowerCase().includes(search.toLowerCase())) });
                    if (!this.state.filteredLanguages.length) {
                        $(".noDataFound").css("display", 'flex')
                    } else {
                        $(".noDataFound").css("display", 'none')
                    }
                    this.update();
                },
                removeSearchLanguageTrim(e) {
                    $("#tp-search").val('')
                    $(".noDataFound").css("display", 'none')
                    this.update({ filteredLanguages: this.state.languages })


                },
                loadFile(path) {
                    return 'https://tamkin.app' + path;
                },
                changeLanguage(e, lang) {
                    var selectBox = document.querySelector("#google_translate_element select.goog-te-combo");
                    if (selectBox && selectBox.value != lang.language_code) {
                        // trigger google change translate the website
                        selectBox.value = lang.language_code;
                        var event = new Event('change', { 'bubbles': true, 'cancelable': true });
                        selectBox.dispatchEvent(event);

                        $('body *:not(div.notranslate *)').each(function () {
                            if ($(this).children().length === 0 && $(this).text().trim() !== '') {
                                $(this).addClass('highlight');
                            }
                        });

                        // sometimes this doesn't work, so i try to trigger it again depending on html tag has eigher 'translated-ltr' or 'translated-rtl'
                        setTimeout(() => {
                            $('.highlight').removeClass('highlight');
                            if (!$('html').hasClass('translated-rtl') && !$('html').hasClass('translated-ltr')) {
                                $(e.target).trigger('click');
                            }

                        }, 1500);
                        this.activateLanguage(lang);

                    }
                },
                sidebarIsHidden() {
                    const hideUntil = localStorage.getItem('tpAccHideUntil');
                    const hide = localStorage.getItem('tpSidebarPosition')
                    if (hide === 'hide') {
                        return true
                    }

                    if (!hideUntil) {
                        return false;
                    } else {
                        if (new Date() < new Date(hideUntil)) {
                            return true;
                        } else {
                            localStorage.removeItem('tpAccHideUntil');
                            return false;
                        }
                    }
                },


            }
        </script>

        <style>
            .tp-lang-modal-icon {
                position: fixed;
                right: -100%;
                transition: 0.4s;
                cursor: pointer;
                z-index: 1041;
            }

            .accessibility-icon-container-lang.ltr .tp-lang-modal-icon {
                left: -100%;
                right: auto;
            }

            .tp-accessibility-lang {
                position: fixed;
                top: 0;
                right: -350px;
                width: 350px;
                z-index: 1042;
                height: 100vh;
                background-color: #FFF;
                border-radius: 8px;
                transition: right 0.7s, left 0.7s;
            }



            .tp-accessibility-lang.active {
                right: 0;
                left: auto;
            }

            .tp-accessibility-lang.ltr {
                left: -350px;
            }

            @media only screen and (max-width: 460px) {
                .tp-accessibility-lang {
                    width: 100% !important;
                    right: -100%;
                }

                .tp-accessibility-lang.ltr {
                    left: -100%;
                }
            }

            .tp-accessibility-lang.ltr.active {
                left: 0px;
                right: auto;
            }

            .tp-accessibility-lang-header {
                height: 56px;
                /* background: linear-gradient(180deg,
                        var(--tp-primary) 0%,
                        var(--tp-primary-grad) 100%); */
                background-color: var(--tp-primary);
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
                border-top-right-radius: 0px;
                border-top-left-radius: 0px;
                padding: 0px 15px;
            }

            .worldbg {
                width: 36px;
                height: 36px;
                border-radius: 50%;
                /* background: linear-gradient(180deg,
                        var(--tp-primary) 0%,
                        var(--tp-primary-grad) 100%); */
                background-color: var(--tp-primary);
            }

            /* .tp-accessibility-lang.ltr .tp-accessibility-lang-header {
                margin-right: auto;
            } */


            .tp-accessibility-lang-close {
                background-color: white;
                width: 35px;
                height: 35px;
                transition: all 0.5s;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .tp-accessibility-lang-close div {
                background-color: #585b5b;
                text-decoration: none;
                width: 20px;
                height: 3px;
                border-radius: 2px;
            }

            .tp-accessibility-lang-close:hover {
                /* background: linear-gradient(180deg,
                        var(--tp-primary) 0%,
                        var(--tp-primary-grad) 100%); */
                background-color: var(--tp-primary);
                box-shadow: 1px 0px 10.6px 0px var(--tp-primary-grad);
            }

            .tp-accessibility-lang-close svg {
                display: none;
            }

            .tp-accessibility-lang-close:hover svg {
                display: block;
            }

            .tp-accessibility-lang-close:hover div {
                display: none;
            }


            /* accordion */
            .list-group-flush>.list-group-item:last-child {
                border-width: 0 0 var(--bs-list-group-border-width);
            }

            .tp-icon {
                width: 36px !important;
                height: 36px !important;
                align-content: center;
                text-align: center;
            }

            .tp-icon.lg {
                width: 50px !important;
                height: 50px !important;
            }

            .tp-icon.sm {
                width: 28px !important;
                height: 28px !important;
            }

            #languages-box .form-group label {
                position: absolute;
                top: 13px;
                left: 1rem;
            }

            #languages-box .list-group-item .tp-icon {
                background-color: var(--tp-primary-light);
                color: var(--tp-primary);
            }

            #languages-box .list-group-item.active .tp-icon,
            #g-lang-active .tp-icon {
                background-color: var(--tp-primary);
                color: white;
            }

            #languages-box .list-group-item.active {
                background-color: var(--tp-primary-light);
                border-color: var(--tp-primary-light);
                color: var(--tp-dark);
            }

            .collapsing {
                transition: all 0.1s !important;
            }

            #languages-box .list-group {
                height: calc(100vh - 56px - 181px);
                overflow-y: auto;
                scrollbar-width: thin;
                scrollbar-color: var(--tp-primary) #f1f1f1;
            }

            #languages-box .list-group-item.active::before {
                content: "✓";
                position: absolute;
                right: 24px;
                top: 14px;
                width: 24px;
                color: var(--tp-primary);
                height: 24px;
                background-size: contain;
                background-repeat: no-repeat;
            }

            .rtl #languages-box .list-group-item.active::before {
                right: auto;
                left: 24px;
            }

            .noDataFound {
                height: calc(100vh - 56px - 181px);
                padding: 20px;
                display: none;
                flex-direction: column;
                justify-content: start;
                align-items: center;
                gap: 8px;
            }

            .noDataFound h3 {
                font-size: 13px;
                font-weight: 600;
                line-height: 19.5px;
                letter-spacing: 0.30000001192092896px;
                text-align: center;
            }

            .noDataFound p {
                font-size: 10px;
                font-weight: 400;
                line-height: 15px;
                letter-spacing: 0.30000001192092896px;
                text-align: center;
            }
        </style>
</language-widget>